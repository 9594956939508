import React from "react";
import cx from "classnames";
import styles from "./Story.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import { FunctionComponent, PropsWithChildren, ReactNode } from "react";
import { FaChevronLeft, FaTimes } from "react-icons/fa";
import { Routes, useLocation, useNavigate } from "react-router-dom";
import { LogoVertical } from "../images/LogoVertical";
import { useStoryValues } from "./useStoryValues";
import { useSetAtom } from "jotai";
import { routeState } from "../../state/routeState";

interface Props {
  headerText: string | ReactNode;
  routes: Record<string, string>;
  baseRoute: string;
  showBack?: boolean;
  showClose?: boolean;
  onBack?: () => void;
  onClose?: () => void;
}

export interface StoryStepProps {
  next: () => void;
  pageRef: React.RefObject<HTMLDivElement>;
}

export const Story: FunctionComponent<PropsWithChildren<Props>> = ({
  headerText,
  baseRoute,
  routes,
  showBack = false,
  showClose = false,
  onBack,
  onClose,
  children,
}) => {
  const navigate = useNavigate();
  const [index, progress, prevRoute] = useStoryValues(baseRoute, routes);
  const location = useLocation();
  // const lastIndex = useRef(index);
  // const animationDirection = useRef<AnimationDirection>("forward");
  const showBackButton = showBack && index > 0;
  const showCloseButton = showClose && onClose;
  const setBack = useSetAtom(routeState);

  // if (lastIndex.current < index) {
  //   animationDirection.current = "forward";
  // } else if (lastIndex.current > index) {
  //   animationDirection.current = "backward";
  // }

  // if (lastIndex.current !== index) {
  //   lastIndex.current = index;
  // }

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <div className={styles.header}>
          <div className={styles.buttonWrapper}>
            {showBackButton && (
              <button
                onClick={() => {
                  setBack((prev) => ({ ...prev, goingBack: true }));
                  setTimeout(() => {
                    window.requestAnimationFrame(() => {
                      onBack ? onBack() : navigate(prevRoute);
                    });
                  }, 0);
                }}
              >
                <FaChevronLeft />
              </button>
            )}
          </div>
          <div className={styles.title}>
            <h2 className={styles.titleText}>{headerText}</h2>
          </div>
          <div className={styles.buttonWrapper}>
            {showCloseButton ? (
              <button onClick={onClose}>
                <FaTimes />
              </button>
            ) : (
              <LogoVertical />
            )}
          </div>
          <motion.span
            className={styles.progressBar}
            animate={{ width: `${progress}%` }}
            transition={{ duration: 0.75, easings: "easeIn" }}
          />
        </div>
      </div>
      <div className={cx("content-wrapper", styles.contentWrapper)}>
        <div className={styles.content}>
          <AnimatePresence initial={false}>
            <Routes key={location.pathname} location={location}>
              {children}
            </Routes>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};
