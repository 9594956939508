import React, { useMemo } from "react";
import { T } from "../../../components/translation/T";
import { useAtom, useAtomValue } from "jotai";
import { routeState } from "../../../state/routeState";
import { StoryStepProps } from "../../../components/story/Story";
import { RadioGroup } from "../../../components/form/RadioGroup";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { StoryContinueButton } from "../../../components/story/StoryContinueButton";
import { Form } from "../../../components/form/Form";
import { useLinkId } from "../../../hooks/useLinkId";
import { FinancialData, dataFinancial } from "../../../data/dataFinancial";
import {
  useMutation,
  useQueryClient,
  useSuspenseQueries,
} from "@tanstack/react-query";
import { AnimateHeight } from "../../../components/animate/AnimateHeight";
import { GenericError } from "../../../components/Errors/GenericError";
import { Alternative } from "../../../components/interactions/InputTypes";
import { Access } from "../../../data/proxy";

function getAlternatives() {
  return [
    {
      value: false,
      text: <T>Yes</T>,
    },
    {
      value: true,
      text: <T>No</T>,
    },
  ];
}

export const PayoutAccount: React.FunctionComponent<StoryStepProps> = ({
  next,
  pageRef,
}) => {
  const linkId = useLinkId();
  const { access } = useAtomValue(routeState);
  const [routingProps, setRoutingProps] = useAtom(routeState);
  const { thirdPartyPayments } = routingProps;
  const queryClient = useQueryClient();

  const [{ data: response }] = useSuspenseQueries({
    queries: [dataFinancial(access).fetchFinancial(linkId)],
  });

  const alternatives: Alternative<boolean>[] = useMemo(
    () => getAlternatives(),
    []
  );

  const {
    mutate: onSave,
    isPending: isLoading,
    isError,
  } = useMutation({
    mutationFn: () => {
      const values = {
        ...response,
        thirdPartyPayments: thirdPartyPayments,
      };

      return dataFinancial(access).postFinancial(
        linkId,
        values as FinancialData
      );
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: dataFinancial(access).getFinancialKey(linkId),
      });

      next();
    },
  });

  return (
    <>
      <h1>
        <T>Payout account</T>
      </h1>
      <p>
        <T>
          The last step in this process is to add a payout account where you
          will receive your means.
        </T>
      </p>
      <Form
        name="select-payout-type"
        id="select-payout-type"
        onSubmit={(_, form) => {
          if (!form.isValid) {
            return;
          }
          onSave();
        }}
      >
        <div className="m-top-40">
          <RadioGroup
            name="thirdPartyPayments"
            className="horizontal"
            label="Will all payments be made to an account directly linked to the company?"
            alternatives={alternatives}
            validators={[new RequiredValidator("Please select an option")]}
            value={thirdPartyPayments}
            // hint={<T>Stripe, Apple Pay, etc</T>}
            onChange={(value) => {
              setRoutingProps((prev) => ({
                ...prev,
                thirdPartyPayments: "true" === (value as unknown as string),
              }));
            }}
          />
        </div>

        <AnimateHeight name={isError ? "error" : ""}>
          {isError ? <GenericError /> : <div />}
        </AnimateHeight>

        <div className="m-top-30">
          <StoryContinueButton
            disabled={access === Access.VIEW}
            type="submit"
            isLoading={isLoading}
          >
            <T>Continue</T>
          </StoryContinueButton>
        </div>
      </Form>
    </>
  );
};
