import React, { useState, useEffect, useCallback } from "react";
import { Status } from "../../data/types";
import { Retry } from "../../components/retry/Retry";
import { Outlet, useParams } from "react-router-dom";
import {
  Country,
  EcomType,
  Language,
  LinkId,
  languageByCountry,
} from "../../data/models/ContractTypes";
import { EcomStore, dataMerchant } from "../../data/dataMerchant";
import { useAtomValue, useSetAtom } from "jotai";
import { routeState } from "../../state/routeState";
import { contractState } from "../../state/contractState";
import { useQueryClient } from "@tanstack/react-query";
import { dataDocuments } from "../../data/dataDocuments";
import { dataAssociates, isSignee } from "../../data/dataAssociates";
import { dataBank } from "../../data/dataBank";
import { useTranslation } from "react-i18next";
import { isEcom } from "../DataCollection/ContractLoader";
import { dataStores } from "../../data/dataStores";
import { Access } from "../../data/proxy";

export type LinkIdParams = {
  code: string;
};

export const VIEW_STORY_URL = "/view/:code/";

export const ViewLoader: React.FunctionComponent = () => {
  const { i18n } = useTranslation();
  const { code } = useParams<LinkIdParams>();
  const { access } = useAtomValue(routeState);
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const linkId = window.atob(code || "") as LinkId;
  const setContract = useSetAtom(contractState);
  const setRouteState = useSetAtom(routeState);
  const queryClient = useQueryClient();

  const load = useCallback(
    (idParam: LinkId) => {
      setStatus(Status.PENDING);

      Promise.all([
        dataMerchant(Access.VIEW).getContract(idParam),
        dataDocuments(Access.VIEW).getDocuments(idParam),
        dataAssociates(Access.VIEW).getAssociates(idParam),
        dataBank(Access.VIEW).getConfirmedStatus(idParam),
      ])
        .then((responses) => {
          const [contract, documents, associates, confirmedBank] = responses;
          queryClient.setQueryData(
            dataDocuments(Access.VIEW).getDocumentsKey(idParam),
            documents
          );
          queryClient.setQueryData(
            dataBank(Access.VIEW).getConfirmedStatusKey(idParam),
            confirmedBank
          );
          queryClient.setQueryData(
            dataAssociates(Access.VIEW).getAssociatesKey(idParam),
            associates
          );

          const everyAssociateHaveId = associates
            .filter((associate) => isSignee(associate))
            .every((associate) => !!associate.id?.nationalPersonId);

          setContract(contract);
          const viewerLanguage: Language =
            contract.contractViewer.language ||
            languageByCountry[contract.contractData.country as Country];

          let lang;
          try {
            const urlSearchParams = new URLSearchParams(window.location.search);
            ({ lang } = Object.fromEntries(urlSearchParams.entries()));
          } catch (err) {}

          i18n.changeLanguage(lang || viewerLanguage);

          let promise;
          if (isEcom(contract)) {
            promise = dataStores(access).getEcomStore(idParam);
          } else {
            promise = dataStores(access).getStores(idParam);
          }

          return promise.then((resp) => {
            if (isEcom(contract)) {
              queryClient.setQueryData(
                dataStores(access).getEcomStoresKey(idParam),
                resp
              );
            } else {
              queryClient.setQueryData(
                dataStores(access).getStoresKey(idParam),
                resp
              );
            }

            setRouteState((prev) => ({
              ...prev,
              hasGenericDocuments: !!documents.length,
              // hasSelfCheckout: !!contract.selfCheckout,
              legalEntityType: contract.contractData.legalEntityType,
              access: Access.VIEW,

              linkId: idParam,
              productType: contract.productType,
              ecomType: (resp as EcomStore).type || EcomType.WEB,
              country: contract.contractData.country,
              everyAssociateHaveId,
              viewOnly: true,
            }));

            setStatus(Status.SUCCESS);
          });
        })
        .catch((err) => {
          console.log("err", err);
          setStatus(Status.ERROR);
        });
    },
    [setContract, queryClient, access, setRouteState, i18n]
  );

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(() => {
      if (!linkId) {
        return;
      }

      load(linkId);
    }, 500);
  }, [load, linkId]);

  useEffect(() => {
    if (!linkId) {
      return;
    }

    load(linkId);
  }, [load, linkId]);

  return (
    <Retry retry={retry} status={status}>
      <Outlet />
    </Retry>
  );
};
