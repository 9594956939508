import cx from "classnames";
import { useMemo, useRef } from "react";
import { Form } from "../../../components/form/Form";
import { HiddenInput } from "../../../components/form/HiddenInput";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { StoryStepProps } from "../../../components/story/Story";
import { StoryContinueButton } from "../../../components/story/StoryContinueButton";
import { T } from "../../../components/translation/T";
import { dataDocuments } from "../../../data/dataDocuments";
import { useLinkId } from "../../../hooks/useLinkId";
import { DocumentUpload } from "./DocumentUpload";
import { DocumentType } from "../../../data/models/ContractTypes";
import { routeState } from "../../../state/routeState";
import { useAtomValue } from "jotai";
import { Access } from "../../../data/proxy";
import styles from "./Documents.module.scss";
import { useSuspenseQueries } from "@tanstack/react-query";

const EXCLUDED_DOCS = [
  DocumentType.RECORD_OF_NOMINEE_SHAREHOLDERS,
  DocumentType.LETTER_OF_SHAREHOLDER_CUSTODIAN,
  DocumentType.CONTROL_STRUCTURE,
  DocumentType.ECOM_APPLICATION_SCREENSHOT,
  DocumentType.SIGNED_CONTRACT,
];

export const Documents: React.FunctionComponent<StoryStepProps> = ({
  next,
}) => {
  const linkId = useLinkId();
  const { access } = useAtomValue(routeState);

  const [{ data: documents }] = useSuspenseQueries({
    queries: [dataDocuments(access).fetchDocuments(linkId)],
  });

  const docs = useMemo(() => {
    return documents.filter((doc) => !EXCLUDED_DOCS.includes(doc.documentType));
  }, [documents]);

  const allDone = useMemo(() => docs.every((doc) => doc.uploaded), [docs]);

  const ref = useRef<HTMLFormElement>(null);

  return (
    <>
      <Form
        name="owners"
        className="flex-1"
        ref={ref}
        onSubmit={(_, form) => {
          if (!form.isValid) {
            return;
          }
          next();
        }}
      >
        <h2>
          <T>Documents</T>
        </h2>

        <p>
          <T>
            We need you to upload a few additional documents in order to
            complete your application
          </T>
        </p>
        <div className={cx("m-top-40", styles.upload)}>
          {docs.map((doc) => (
            <DocumentUpload
              key={doc.documentId}
              document={doc}
              link={linkId}
              disabled={access === Access.VIEW}
            />
          ))}
        </div>
        <HiddenInput
          label="Completed documents"
          value={allDone ? true : undefined}
          validators={[
            new RequiredValidator(
              "There are one or more documents that needs to be uploaded"
            ),
          ]}
          scrollToRef={ref}
        />
        <StoryContinueButton
          disabled={access === Access.VIEW}
          type="submit"
          className="m-top-30"
        >
          <T>Continue</T>
        </StoryContinueButton>
      </Form>
    </>
  );
};
