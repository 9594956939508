import { useAtomValue } from "jotai";
import { StoryStepProps } from "../../../components/story/Story";
import { useLinkId } from "../../../hooks/useLinkId";
import { routeState } from "../../../state/routeState";
import { useQueries } from "@tanstack/react-query";
import { Combination, dataAssociates } from "../../../data/dataAssociates";
import { SignatoriesFromCombinations } from "./signatoryCombinations/SignatoriesFromCombinations";
import { SignatoriesMissing } from "./signatoriesMissing/SignatoriesMissing";

function filteredCombinations(combinations: Combination[]): Combination[] {
  return combinations.filter((combo) => !!combo.associateIds.length);
}

export const Signatories: React.FunctionComponent<StoryStepProps> = (props) => {
  const linkId = useLinkId();
  const { access } = useAtomValue(routeState);
  const [{ data: signingCombinations = [] }] = useQueries({
    queries: [dataAssociates(access).fetchSignatoryCombinations(linkId)],
  });

  if (
    !signingCombinations.length ||
    !filteredCombinations(signingCombinations).length
  ) {
    return <SignatoriesMissing {...props} />;
  }

  return <SignatoriesFromCombinations {...props} />;
};
