import React from "react";
import { StoryStepProps } from "../../../components/story/Story";
import { T } from "../../../components/translation/T";
import { StoryContinueButton } from "../../../components/story/StoryContinueButton";
import { Trans, useTranslation } from "react-i18next";
import { dataAssociates, isSelectedSignee } from "../../../data/dataAssociates";
import { useLinkId } from "../../../hooks/useLinkId";
import { useAtomValue } from "jotai";
import { useViewerAsAssociate } from "../../../hooks/useViewerAsAssociate";
import { contractState } from "../../../state/contractState";
import { SuccessBox } from "../../../components/boxes/SuccessBox";
import { useAccess } from "../../../hooks/useAccess";
import { Access } from "../../../data/proxy";
import { useSuspenseQueries } from "@tanstack/react-query";

export const ConfirmWarning: React.FunctionComponent<StoryStepProps> = ({
  next,
}) => {
  const linkId = useLinkId();
  const contract = useAtomValue(contractState);
  const access = useAccess();
  const { t } = useTranslation();

  const [{ data: associates }] = useSuspenseQueries({
    queries: [dataAssociates(access).fetchAssociates(linkId)],
  });

  const viewerAsAssociate = useViewerAsAssociate(
    associates,
    contract.contractViewer
  );

  const viewerIsSignee = isSelectedSignee(viewerAsAssociate);
  let header = (
    <h2>
      <T>Ready for signing!</T>
    </h2>
  );
  if (!viewerIsSignee) {
    header = (
      <h2>
        <T>Ready for confirmation!</T>
      </h2>
    );
  }

  return (
    <>
      {header}
      <div className="m-top-20" />
      <div>
        <SuccessBox relative>
          <strong className="text-large">
            <T>Good times!</T>
          </strong>
          <div className="m-top-10" />
          <Trans t={t}>
            Click "continue" to submit your application to Worldline
          </Trans>
        </SuccessBox>
      </div>
      <div className="m-top-40">
        <StoryContinueButton onClick={next} disabled={access === Access.VIEW}>
          <T>Continue</T>
        </StoryContinueButton>
      </div>
    </>
  );
};
