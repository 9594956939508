import React, { useRef } from "react";
import { useAtomValue } from "jotai";
import { contractState } from "../../../state/contractState";
import { SummaryInner } from "../../DataCollection/Summary/Summary";
import { StoryPage } from "../../../components/story/StoryPage";
import { useLinkId } from "../../../hooks/useLinkId";
import { dataFinancial } from "../../../data/dataFinancial";
import { Access } from "../../../data/proxy";
import { T } from "../../../components/translation/T";
import { useSuspenseQueries } from "@tanstack/react-query";

interface Props {
  hasAddedBankAccount?: boolean;
}

export const Sign: React.FunctionComponent<Props> = ({
  hasAddedBankAccount,
}) => {
  const contract = useAtomValue(contractState);
  const ref = useRef<HTMLDivElement>(null);
  const linkId = useLinkId();

  const [{ data: financial }] = useSuspenseQueries({
    queries: [dataFinancial(Access.VIEW_AND_EDIT).fetchFinancial(linkId)],
  });

  return (
    <StoryPage ref={ref}>
      <SummaryInner data={contract.contractData} financial={financial}>
        {hasAddedBankAccount ? (
          <div>
            <h3>
              <T>Great</T>!
            </h3>
            <p>
              <T>Just a signature and we're done here.</T>
            </p>
          </div>
        ) : (
          <div />
        )}
      </SummaryInner>
    </StoryPage>
  );
};
