import React, { useEffect } from "react";
import styles from "./Done.module.scss";
import { T } from "../../components/translation/T";
import { Logo } from "../../components/images/Logo";
import { dataAssociates, isSelectedSignee } from "../../data/dataAssociates";
import { useLinkId } from "../../hooks/useLinkId";
import { Signature } from "../DataCollection/Summary/Signature";
import { Card } from "../../components/cards/Card";
import { getApplicationPreviewLink } from "../DataCollection/Summary/Signatures";
import { Link } from "../../components/link/Link";
import { Access } from "../../data/proxy";
import { useSuspenseQueries } from "@tanstack/react-query";

export const DONE_URL = "/:linkId/done";

export const Done: React.FunctionComponent = () => {
  const linkId = useLinkId();

  const [{ data: associates }] = useSuspenseQueries({
    queries: [dataAssociates(Access.VIEW_AND_EDIT).fetchAssociates(linkId)],
  });

  useEffect(() => {
    const fontFile = new FontFace(
      "Rock Salt",
      "url(https://fonts.gstatic.com/s/rocksalt/v18/MwQ0bhv11fWD6QsAVOZrt0M6p7NGrQ.woff2)"
    );

    document.fonts.add(fontFile);
    fontFile
      .load()
      .then(() => {})
      .catch(() => {});
  }, []);

  const signees = associates.filter((signee) => isSelectedSignee(signee));
  const allSigned = signees.every((associate) => !!associate.signatory?.signed);

  const elem = allSigned ? (
    <T>We will proceed with the application.</T>
  ) : (
    <T>
      We are still waiting for some signatures, before we can proceed with the
      application.
    </T>
  );

  return (
    <section>
      <article>
        <div className={styles.wrapper}>
          <div className="logo-wrapper">
            <Logo />
          </div>
          <div className={styles.header}>
            <h2>
              <T>You are done!</T>
            </h2>
          </div>
          <p>
            <T>Thank you for providing the information.</T> {elem}
          </p>

          <div className={styles.preview}>
            <Link
              className="as-button ghost"
              external
              link={getApplicationPreviewLink(linkId)}
            >
              <T>Preview application</T>
            </Link>
          </div>

          <Card className={styles.card}>
            <div className="text-large">
              <T>Signatories</T>
            </div>

            <ul className={styles.signature}>
              {signees.map((signee) => (
                <Signature signee={signee} key={signee.associateId} />
              ))}
            </ul>
          </Card>
        </div>
      </article>
    </section>
  );
};
