import React, { Suspense } from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter as Router,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { Pending } from "./components/icons/Pending";
import * as Sentry from "@sentry/react";
import { APP_ID } from "./network/API";
import "./index.scss";
import "./ui/typography.scss";
import "react-loading-skeleton/dist/skeleton.css";

Sentry.init({
  dsn: "https://a37e1626683a49afaed7f8fd788a0026@o4504866465185792.ingest.sentry.io/4504876975325184",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 0.5,
  enabled: process.env.NODE_ENV === "production",
  environment: process.env.REACT_APP_ENV,
});

Sentry.setContext("session", {
  appId: APP_ID,
});

declare global {
  interface Window {
    scripts: any;
    maps: any;
    XS2A: any;
    kontonummer: any;
    dataLayer: any;
  }
}

window.scripts = window.scripts || {
  loaded: {},
};

window.scripts.importScript = (url: string) => {
  return new Promise<void>((resolve, reject) => {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.addEventListener("load", () => resolve(), false);
    script.addEventListener("error", () => reject(), false);
    document.body.appendChild(script);
  });
};

const domNode = document.getElementById("root");
const root = createRoot(domNode as Element);

root.render(
  <Suspense
    fallback={
      <div style={{ margin: "100px auto" }}>
        <section>
          <article>
            Loading <Pending />
          </article>
        </section>
      </div>
    }
  >
    <Router>
      <App />
    </Router>
  </Suspense>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
