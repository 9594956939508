import React, { useState, useEffect, useMemo } from "react";
import styles from "./Signatures.module.scss";
import { Status } from "../../../data/types";
import { useAtomValue } from "jotai";
import {
  Associate,
  EcomStore,
  Store,
  TerminalIntegrated,
} from "../../../data/dataMerchant";
import { ViewerIsNotSignee } from "./signeeState/ViewerIsNotSignee";
import { Signature } from "./Signature";
import { ViewerIsSigneeNotSigned } from "./signeeState/ViewerIsSigneeNotSigned";
import { ViewerIsSigneeSigned } from "./signeeState/ViewerIsSigneeSigned";
import { useCountry } from "../../../hooks/useCountry";
import { useLinkId } from "../../../hooks/useLinkId";
import { dataAssociates, isSelectedSignee } from "../../../data/dataAssociates";
import { contractState } from "../../../state/contractState";
import { useViewerAsAssociate } from "../../../hooks/useViewerAsAssociate";
import { TermsAndConditions } from "./TermsAndConditions";
import { Link } from "../../../components/link/Link";
import { T } from "../../../components/translation/T";
import { LinkId } from "../../../data/models/ContractTypes";
import { routeState } from "../../../state/routeState";
import { dataStores } from "../../../data/dataStores";
import { isEcom } from "../ContractLoader";
import { getUploadBaseUrl } from "../../../components/uploads/FileUpload";
import { useSuspenseQueries } from "@tanstack/react-query";

const DCC_ENABLED_KEY = "DCCEnabled";

export function getApplicationPreviewLink(linkId: LinkId) {
  return `${getUploadBaseUrl()}/api/merchant/${linkId}/contract`;
}

export function getPosition(position?: string) {
  if (!position) {
    return "";
  }

  return `${position}, `;
}

export function getNameWithPosition(signee?: Associate) {
  if (!signee) {
    return "";
  }

  return (
    <>
      {getPosition(signee.contact.position)}{" "}
      <span>
        {signee.contact.firstName} {signee.contact.lastName}
      </span>
    </>
  );
}

function hasDccByOptions(terminalIntegrated?: TerminalIntegrated) {
  if (!terminalIntegrated?.terminalOptions) {
    return false;
  }

  const item = terminalIntegrated.terminalOptions.find(
    (item) => item.name === DCC_ENABLED_KEY
  );

  return item?.value === "true";
}

function hasDCC(stores: Store[] | EcomStore) {
  if (!Array.isArray(stores)) {
    return false;
  }

  return stores.some(
    (store) =>
      hasDccByOptions(store.wirelessStandalone as TerminalIntegrated) ||
      hasDccByOptions(store.wirelessIntegrated) ||
      hasDccByOptions(store.wiredIntegrated)
  );
}

export const Signatures: React.FunctionComponent = () => {
  const linkId = useLinkId();
  const country = useCountry();
  const contract = useAtomValue(contractState);
  const { access } = useAtomValue(routeState);
  const [, setStatus] = useState<Status>(Status.DEFAULT);

  const [{ data }, { data: stores }] = useSuspenseQueries({
    queries: [
      dataAssociates(access).fetchAssociates(linkId),
      isEcom(contract)
        ? dataStores(access).fetchEcomStore(linkId)
        : dataStores(access).fetchStores(linkId),
    ],
  });

  const contractIncludesDcc = useMemo(() => hasDCC(stores), [stores]);

  const [associates, setAssociates] = useState<Associate[]>([]);
  const { contractViewer } = useAtomValue(contractState);
  const viewerAsAssociate = useViewerAsAssociate(associates, contractViewer);
  const viewerIsSignee = isSelectedSignee(viewerAsAssociate);
  const viewerHasSigned = !!(
    viewerIsSignee && !!viewerAsAssociate?.signatory?.signed
  );
  // const doc = viewerAsAssociate?.documents.powerOfAttorney;
  // const isPowerOfAttorney = !!doc;
  // const viewerHasUploaded = !!doc?.uploaded;

  useEffect(() => {
    setAssociates(data);
  }, [data]);

  const signeesViewerIncluded = useMemo(
    () => associates.filter((associate) => isSelectedSignee(associate as any)),
    [associates]
  );

  const signeesViewerExcluded = useMemo(
    () =>
      signeesViewerIncluded.filter(
        (associate) => viewerAsAssociate?.associateId !== associate.associateId
      ),
    [viewerAsAssociate?.associateId, signeesViewerIncluded]
  );

  useEffect(() => {
    const fontFile = new FontFace(
      "Rock Salt",
      "url(https://fonts.gstatic.com/s/rocksalt/v18/MwQ0bhv11fWD6QsAVOZrt0M6p7NGrQ.woff2)"
    );

    document.fonts.add(fontFile);
    fontFile
      .load()
      .then(() => setStatus(Status.SUCCESS))
      .catch(() => setStatus(Status.ERROR));
  }, []);

  if (!viewerAsAssociate) {
    return null;
  }

  if (!viewerIsSignee) {
    return (
      <>
        <div className={styles.viewer}>
          <div className={styles.preview}>
            <Link
              className="as-button ghost"
              external
              link={getApplicationPreviewLink(linkId)}
            >
              <T>Preview application</T>
            </Link>
          </div>
          <ViewerIsNotSignee signees={signeesViewerIncluded} />
        </div>

        <h4>
          <T>Signatories</T>
        </h4>
        <ul className={styles.signature}>
          {signeesViewerIncluded.map((signee) => (
            <Signature signee={signee} key={signee.associateId} />
          ))}
        </ul>
      </>
    );
  }

  // if (isPowerOfAttorney) {
  //   if (viewerHasSigned && viewerHasUploaded) {
  //     return (
  //       <>
  //         <div className={styles.viewer}>
  //           <ViewerIsSigneeSigned
  //             signees={signeesViewerIncluded as Associate[]}
  //           />
  //         </div>

  //         <ul className={styles.signature}>
  //           {signeesViewerIncluded.map((signee) => (
  //             <Signature
  //               signee={signee as Associate}
  //               key={signee.associateId}
  //             />
  //           ))}
  //         </ul>
  //       </>
  //     );
  //   } else {
  //     return (
  //       <>
  //         <div className={styles.viewer}>
  //           <ViewerIsAttorneyNotSigned
  //             viewer={viewerAsAssociate}
  //             country={country}
  //             setAssociates={setAssociates}
  //             associates={associates}
  //           />
  //         </div>

  //         <ul className={styles.signature}>
  //           {signeesViewerExcluded.map((signee) => (
  //             <Signature
  //               signee={signee as Associate}
  //               key={signee.associateId}
  //             />
  //           ))}
  //         </ul>
  //       </>
  //     );
  //   }
  // }

  if (viewerHasSigned) {
    return (
      <>
        <div className={styles.viewer}>
          <div className={styles.preview}>
            <Link
              className="as-button ghost"
              external
              link={getApplicationPreviewLink(linkId)}
            >
              <T>Preview application</T>
            </Link>
          </div>
          <div className="m-bottom-20">
            <TermsAndConditions
              setAcceptedTerms={() => {}}
              acceptedTerms={true}
              contract={contract}
              contractIncludesDcc={contractIncludesDcc}
              disabled
            />
          </div>

          <ViewerIsSigneeSigned
            signees={signeesViewerIncluded as Associate[]}
          />
        </div>
        <h4>
          <T>Signatories</T>
        </h4>
        <ul className={styles.signature}>
          {signeesViewerIncluded.map((signee) => (
            <Signature signee={signee as Associate} key={signee.associateId} />
          ))}
        </ul>
      </>
    );
  }

  return (
    <>
      <div className={styles.viewer}>
        <div className={styles.preview}>
          <Link
            className="as-button ghost"
            external
            link={getApplicationPreviewLink(linkId)}
          >
            <T>Preview application</T>
          </Link>
        </div>

        <ViewerIsSigneeNotSigned
          viewer={viewerAsAssociate}
          country={country}
          setAssociates={setAssociates}
          associates={associates}
          contract={contract}
          contractIncludesDcc={contractIncludesDcc}
        />
      </div>

      <ul className={styles.signature}>
        {signeesViewerExcluded.map((signee) => (
          <Signature signee={signee as Associate} key={signee.associateId} />
        ))}
      </ul>
    </>
  );
};
