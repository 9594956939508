import React, { useState, useMemo, ReactNode } from "react";
import styles from "./Bankgiro.module.scss";
import Kontonummer from "kontonummer";
import { StoryStepProps } from "../../../../components/story/Story";
import { useLinkId } from "../../../../hooks/useLinkId";
import { ConfirmedStatus, dataBank } from "../../../../data/dataBank";
import { useAtom } from "jotai";
import { routeState } from "../../../../state/routeState";
import { Trans, useTranslation } from "react-i18next";
import {
  useMutation,
  useQueryClient,
  useSuspenseQueries,
} from "@tanstack/react-query";
import { Form } from "../../../../components/form/Form";
import { Card } from "../../../../components/cards/Card";
import { TextInput } from "../../../../components/form/TextInput";
import { T } from "../../../../components/translation/T";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { BankValidatorMinLength } from "../../../../components/form/validators/BankValidatorMinLength";
import { BankValidatorMaxLength } from "../../../../components/form/validators/BankValidatorMaxLength";
import { Dynamic } from "../../../../components/animate/Dynamic";
import { Beacon } from "../../../../components/beacon/Beacon";
import { WarningBox } from "../../../../components/boxes/WarningBox";
import { GenericError } from "../../../../components/Errors/GenericError";
import { Access } from "../../../../data/proxy";
import { StoryContinueButton } from "../../../../components/story/StoryContinueButton";
import { Validity } from "../../../../data/types";
import { useAccess } from "../../../../hooks/useAccess";
import { SavedAccount } from "../SavedAccount";

interface Props {
  next: () => void;
  buttonName?: ReactNode;
  externalAccount: ConfirmedStatus;
  disabled?: boolean;
  hideButton?: boolean;
}

const MIN_LENGTH_ACCOUNT = 7;
const MAX_LENGTH_ACCOUNT = 10;
const MIN_LENGTH_CLEARING = 4;
const MAX_LENGTH_CLEARING = 5;

function cleanNumber(numberAsString?: string) {
  if (!numberAsString) {
    return "";
  }

  return numberAsString.replace(/\D/g, "");
}

export const Bankgiro: React.FunctionComponent<StoryStepProps> = ({ next }) => {
  const linkId = useLinkId();
  const access = useAccess();
  const disabled = access === Access.VIEW;

  const [{ data: externalAccount }] = useSuspenseQueries({
    queries: [dataBank(access).fetchConfirmedStatus(linkId)],
  });

  return (
    <>
      <h2>
        <T>Payout account</T>
      </h2>
      <p>
        <T>
          We need you to add a payout account that will be used for your
          transactions.
        </T>
      </p>

      <SavedAccount
        next={next}
        externalAccount={externalAccount}
        disabled={disabled}
      />

      <BankgiroInner next={next} externalAccount={externalAccount} />
    </>
  );
};

export const BankgiroInner: React.FunctionComponent<Props> = ({
  next,
  buttonName = <T>Continue</T>,
  externalAccount,
  disabled: isDisabled,
  hideButton = false,
}) => {
  const { t } = useTranslation();
  const [{ access }, setRoutingProps] = useAtom(routeState);
  const linkId = useLinkId();

  const [bankAccount, setBankAccount] = useState<string>("");
  const [clearing, setClearing] = useState<string>("");
  const [info, setInfo] = useState<Kontonummer | null>(null);
  const [hasShowedWarning, setHasShowedWarning] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const disabled = isDisabled || access === Access.VIEW;

  const showBankName = useMemo(() => {
    return (
      !!info &&
      cleanNumber(clearing).length + cleanNumber(bankAccount).length >=
        MIN_LENGTH_CLEARING + MIN_LENGTH_ACCOUNT
    );
  }, [info, clearing, bankAccount]);

  const {
    mutate: onSave,
    isPending: isSaving,
    isError,
    reset,
  } = useMutation({
    mutationFn: () => {
      return dataBank(access).reqeustBankgiroVerificationV2(
        linkId,
        cleanNumber(clearing),
        cleanNumber(bankAccount)
      );
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: dataBank(access).getConfirmedStatusKey(linkId),
      });

      setRoutingProps((prev) => ({
        ...prev,
        payoutAccountHasBeenAdded: true,
      }));
      next();
    },
  });

  return (
    <Form
      name="bank-account"
      onSubmit={(_, form) => {
        if (!form.isValid) {
          return;
        }

        if (!info?.valid && !hasShowedWarning) {
          setHasShowedWarning(true);
          return;
        }

        setHasShowedWarning(false);
        onSave();
      }}
    >
      <Card className={styles.card}>
        <p>
          <Trans t={t}>
            The account will be <u>verified by bankgiro</u>.
          </Trans>
        </p>
        <p>
          <b>
            <T>Exempel</T>:
          </b>{" "}
          3300 7810251111
        </p>
        <div className="m-top-40">
          <div className="tablet-columns">
            <div>
              <TextInput
                name="clearing"
                label="Clearing number"
                value={clearing}
                disabled={disabled}
                validators={[
                  new RequiredValidator("Required"),
                  new BankValidatorMinLength(
                    MIN_LENGTH_CLEARING,
                    (
                      <T
                        id={"At least {{min}} digits are required"}
                        options={{
                          min: MIN_LENGTH_CLEARING,
                        }}
                      />
                    )
                  ),
                  new BankValidatorMaxLength(
                    MAX_LENGTH_CLEARING,
                    (
                      <T
                        id={"Not more than {{max}} digits are allowed"}
                        options={{
                          max: MAX_LENGTH_CLEARING,
                        }}
                      />
                    )
                  ),
                ]}
                onChange={(value) => {
                  reset();
                  setClearing(value);
                  setHasShowedWarning(false);
                  try {
                    const info = Kontonummer.parse(value, bankAccount || "", {
                      mode: "strict",
                    });
                    setInfo(info);
                  } catch (err) {
                    setInfo(null);
                  }
                }}
              />
            </div>
            <div>
              <TextInput
                name="bankAccount"
                label="Account number"
                value={bankAccount}
                disabled={disabled}
                validators={[
                  new RequiredValidator("Required"),
                  new BankValidatorMinLength(
                    MIN_LENGTH_ACCOUNT,
                    (
                      <T
                        id={"At least {{min}} digits are required"}
                        options={{
                          min: MIN_LENGTH_ACCOUNT,
                        }}
                      />
                    )
                  ),
                  new BankValidatorMaxLength(
                    MAX_LENGTH_ACCOUNT,
                    (
                      <T
                        id={"Not more than {{max}} digits are allowed"}
                        options={{
                          max: MAX_LENGTH_ACCOUNT,
                        }}
                      />
                    )
                  ),
                ]}
                onChange={(value) => {
                  reset();
                  setBankAccount(value);
                  setHasShowedWarning(false);
                  try {
                    const info = Kontonummer.parse(clearing, value, {
                      mode: "strict",
                    });
                    setInfo(info);
                  } catch (err) {
                    setInfo(null);
                  }
                }}
              />
            </div>
          </div>
        </div>

        <Dynamic name={showBankName ? "account" : ""}>
          {info && showBankName ? (
            <div className={styles.info}>
              <Beacon className="mini" validity={Validity.VALID} />
              <b>{info.bankName}</b> - {`${info.format("pretty")} `}
            </div>
          ) : (
            <div />
          )}
        </Dynamic>
      </Card>

      <Dynamic name={hasShowedWarning ? "warning" : ""}>
        {hasShowedWarning ? (
          <div className={styles.warning}>
            <WarningBox relative>
              <Trans>
                Bank accounts are sometimes hard to verify. In this case, we are{" "}
                <b>unable to verify</b> the entered account number. Please check
                the account number again. If it looks good, you may proceed.
              </Trans>
            </WarningBox>
          </div>
        ) : null}
      </Dynamic>

      <Dynamic name={isError ? "error" : ""}>
        {isError ? (
          <div className={styles.error}>
            <GenericError />
          </div>
        ) : null}
      </Dynamic>

      <Dynamic name={hideButton ? "hide" : "show"}>
        {hideButton ? null : (
          <StoryContinueButton
            type="submit"
            isLoading={isSaving}
            disabled={disabled}
            className="m-top-20"
            ghost={externalAccount?.done}
          >
            {buttonName}
          </StoryContinueButton>
        )}
      </Dynamic>
    </Form>
  );
};
