import { useMemo } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useAtomValue } from "jotai";
import { contractState } from "../../state/contractState";
import { routeState } from "../../state/routeState";
import { useDataCollectionRoutes } from "../DataCollection/useDataCollectionRoutes";
import { T } from "../../components/translation/T";
import styles from "./ViewStory.module.scss";

export const ViewStory = () => {
  const state = useAtomValue(routeState);
  const location = useLocation();
  const { contractData } = useAtomValue(contractState);
  const { routes, routeElements } = useDataCollectionRoutes(
    contractData.legalEntityType,
    state.productType,
    state
  );

  const fallbackRoute = useMemo(
    () => Object.values(routes)?.[0] || "/",
    [routes]
  );

  return (
    <section className={styles.viewStory}>
      <div className={styles.overlay}>
        <div className={styles.top}>
          <div>
            <T>
              This is what the merchants sees. The information must be supplied
              by the merchant and we are not allowed to edit.
            </T>
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <Routes location={location}>
          {routeElements}
          <Route path="*" element={<Navigate replace to={fallbackRoute} />} />
        </Routes>
      </div>
    </section>
  );
};
