import React, {
  forwardRef,
  FunctionComponent,
  PropsWithChildren,
  Suspense,
  useEffect,
} from "react";
import { ErrorBoundary } from "@sentry/react";
import { T } from "../translation/T";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import Skeleton from "react-loading-skeleton";
import { StoryContinueButton } from "./StoryContinueButton";
import { useLocation } from "react-router-dom";

interface Props {
  /**
   * A React component that will be rendered while the story page is loading.
   */
  skeleton?: React.ReactNode;
}

export const StoryPage = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ children, skeleton }, ref) => {
    const location = useLocation();

    return (
      <div ref={ref}>
        <QueryErrorResetBoundary>
          {({ reset }) => (
            <>
              <ResetBoundary reset={reset} />
              <ErrorBoundary
                key={location.pathname}
                onReset={reset}
                fallback={({ error, resetError }) => (
                  <>
                    <h1>
                      <T>Oops, something went wrong</T>
                    </h1>
                    <p>
                      <T>Please try again...</T>
                    </p>
                    {/* if development */}
                    {process.env.NODE_ENV === "development" && (
                      <pre
                        style={{
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-word",
                        }}
                      >
                        {(error as any)?.stack}
                      </pre>
                    )}

                    <StoryContinueButton onClick={resetError}>
                      <T>Try again</T>
                    </StoryContinueButton>
                  </>
                )}
              >
                <Suspense
                  fallback={
                    skeleton || (
                      <>
                        <h1>
                          <Skeleton />
                        </h1>
                        <div>
                          <Skeleton count={1} />
                          <Skeleton height={120} className="m-top-10" />
                        </div>
                      </>
                    )
                  }
                >
                  {children}
                </Suspense>
              </ErrorBoundary>
            </>
          )}
        </QueryErrorResetBoundary>
      </div>
    );
  }
);

const ResetBoundary: FunctionComponent<{ reset: () => void }> = ({ reset }) => {
  const location = useLocation();

  // Reset the error queries on page change
  // e.g. going back in story instead of presseing retry
  useEffect(() => {
    reset();
  }, [location.pathname, reset]);

  return null;
};
