import cx from "classnames";
import React, {
  ChangeEvent,
  ReactNode,
  RefObject,
  useCallback,
  useRef,
  useState,
} from "react";
import { NumericFormat } from "react-number-format";
import { Dynamic, Status } from "../../../data/types";
import { Checkmark } from "../../icons/Checkmark";
import { Disabled } from "../../icons/Disabled";
import { Error } from "../../icons/Error";
import { Pending } from "../../icons/Pending";
import {
  createTranslation,
  createTranslationOrEmpty,
  T,
} from "../../translation/T";
import { id as generateId } from "../../utils";
import "../Input/Base.scss";
import "../Input/Input.scss";

export interface Props {
  status?: Status;
  className?: string;
  value?: number;
  placeholder?: string;
  hint?: string | React.ReactNode;
  label?: string | React.ReactNode;
  onChange: (
    value: number | undefined,
    name: string,
    ev?: React.ChangeEvent<any>
  ) => void;
  onBlur?: (value: number | undefined, name: string) => void;
  name?: string;
  message?: string | React.ReactNode;
  autoFocus?: boolean;
  type?: string;
  attributes?: Dynamic;
  list?: string;
  required?: boolean;
  autocomplete?: string;
  id?: string;
  pattern?: string;
  max?: number;
  min?: number;
  suffix?: string;
  prefix?: string;
  onFocus?: (value: number | undefined) => void;
  onKeyUp?: (
    keyCode: number,
    ctrlKey: boolean,
    shiftKey: boolean,
    altKey: boolean
  ) => void;
  ref: RefObject<HTMLElement | null>;
}

export interface StatusMessage {
  status: Status;
  message?: ReactNode | string;
}

const Input: React.FunctionComponent<Props> = ({
  status = Status.DEFAULT,
  className,
  name,
  label = null,
  value,
  onChange,
  onBlur,
  onFocus: focus,
  message = null,
  hint = null,
  placeholder = "",
  autoFocus,
  type,
  attributes = {},
  list,
  required,
  autocomplete,
  id,
  pattern,
  max,
  min,
  suffix,
  prefix,
  onKeyUp,
  ref,
}) => {
  //Det här kanske är fugly att dra in externt beroende, men de finns en textsträng här som jag måste komma åt för att översätta..
  //Ett alternativ kanske är att göra det till en icon istället?
  const identifier = useRef<string>(id ?? generateId());
  const prevValue = useRef<number | undefined>(value);
  const [internalStatus, setInternalStatus] = useState<StatusMessage>({
    status: Status.DEFAULT,
  });

  const change = useCallback(
    (value: number | undefined, ev?: ChangeEvent<any>) => {
      onChange(value, name || identifier.current, ev);
      setTimeout(() => {
        setInternalStatus((prevStatus) => ({
          ...prevStatus,
          status: Status.DEFAULT,
        }));
      }, 0);
    },
    [onChange, name]
  );

  const onFocus = useCallback(() => {
    focus && focus(isNaN(value as any) ? undefined : Number(value));
    prevValue.current = value;
  }, [value, focus]);

  const blur = useCallback(() => {
    // This is so ugly, but will fix that with new input components
    // If the datepicker is used prevValue will correspond to our
    // latest value
    if (type === "date") {
      onBlur && onBlur(value, name || identifier.current);
      return;
    }

    onBlur && onBlur(value, name || identifier.current);
  }, [onBlur, value, name, type]);

  const keyUp = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      onKeyUp && onKeyUp(ev.keyCode, ev.ctrlKey, ev.shiftKey, ev.altKey);
    },
    [onKeyUp]
  );

  const props: Dynamic = attributes;

  if (type) {
    props.type = type;
  }

  if (list) {
    props.list = list;
  }

  let consolidatedMessage = message;
  let consolidatedStatus = status;
  if (status === Status.DEFAULT) {
    consolidatedStatus = internalStatus.status;
    consolidatedMessage = internalStatus.message || "";
  }

  return (
    <label
      className={cx("input", "input-text", className, consolidatedStatus)}
      htmlFor={identifier.current}
    >
      <div className="input-label-wrapper">
        <div className="input-label">
          <div className="input-label-tag">
            {createTranslationOrEmpty(label)}
          </div>
        </div>
        {!value && required && (
          <div className="required-marker text-small">
            <T>required</T>
          </div>
        )}
      </div>

      <div className="input-frame">
        <NumericFormat
          name={name || identifier.current}
          id={identifier.current}
          value={value?.toString()}
          onBlur={blur}
          onFocus={onFocus}
          placeholder={placeholder}
          disabled={status === Status.DISABLED}
          autoFocus={autoFocus}
          autoComplete={autocomplete}
          onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => keyUp(e)}
          getInputRef={ref}
          pattern={pattern}
          // Numeric specific props
          thousandSeparator=" "
          decimalSeparator=","
          inputMode="decimal"
          valueIsNumericString={true}
          onValueChange={(values, event) => {
            change(values.floatValue, event.event);
          }}
          suffix={suffix ? suffix : ""}
          prefix={prefix ? prefix : ""}
          type="text"
          isAllowed={(values) => {
            const { floatValue } = values;
            if (!floatValue) {
              return true;
            }
            return (
              floatValue <= (max || Number.MAX_SAFE_INTEGER) &&
              floatValue >= (min || Number.MIN_SAFE_INTEGER)
            );
          }}
          allowedDecimalSeparators={[".", ","]}
          {...props}
        />

        <div className="input-status">
          <Checkmark />
          <Disabled />
          <Pending />
          <Error />
        </div>
      </div>

      <div className="input-messages">
        <div className="input-hint">{createTranslation(hint)}</div>
        <div className="input-message">
          {createTranslation(consolidatedMessage)}
        </div>
      </div>
    </label>
  );
};

export { Input as Number };
