import { useState } from "react";
import { StoryStepProps } from "../../../components/story/Story";
import { T } from "../../../components/translation/T";
import { ConfirmedStatus, dataBank } from "../../../data/dataBank";
import { useLinkId } from "../../../hooks/useLinkId";
import { BankStatement } from "./bankStatement/BankStatement";
import { SavedAccount } from "./SavedAccount";
import { useAccess } from "../../../hooks/useAccess";
import { Access } from "../../../data/proxy";
import styles from "./Bank.module.scss";
import { useAtomValue } from "jotai";
import { routeState } from "../../../state/routeState";
import { Roaring } from "./roaring/Roaring";
import { useSuspenseQueries } from "@tanstack/react-query";

export enum View {
  KLARNA = "KLARNA",
  BANK_STATEMENT = "BANK_STATEMENT",
  BANKGIRO = "BANKGIRO",
}

export const Bank: React.FunctionComponent<StoryStepProps> = ({
  next,
  pageRef,
}) => {
  const access = useAccess();
  const linkId = useLinkId();
  const [{ data: savedAccount }] = useSuspenseQueries({
    queries: [dataBank(access).fetchConfirmedStatus(linkId)],
  });

  const state = useAtomValue(routeState);
  const externalAccount = savedAccount as unknown as ConfirmedStatus;

  const [, setView] = useState<View>(View.BANK_STATEMENT);

  if (state.roaring) {
    return (
      <div className={styles.bank} ref={pageRef}>
        <Roaring
          next={next}
          externalAccount={externalAccount}
          disabled={access === Access.VIEW}
        />
      </div>
    );
  }

  return (
    <>
      <div className={styles.bank} ref={pageRef}>
        <h2>
          <T>Payout account</T>
        </h2>
        <p>
          <T>
            We need you to add a payout account that will be used for your
            transactions.
          </T>
        </p>
      </div>

      <SavedAccount
        disabled={access === Access.VIEW}
        next={next}
        externalAccount={externalAccount}
      />

      <div className="m-top-20">
        <BankStatement
          next={next}
          externalAccount={externalAccount}
          disabled={access === Access.VIEW}
          onViewChange={setView}
        />
      </div>
    </>
  );
};
