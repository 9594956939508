import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from "react";
import cx from "classnames";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Associate, Id, Pep, PEP_TYPE } from "../../../../data/dataMerchant";
import { EditRow } from "../../../../components/editRow/EditRow";
import styles from "./PepOverlay.module.scss";
import { PersistentOverlay } from "../../../../components/overlay/PersistantOverlay";
import { Steps } from "../../../../components/steps/Steps";
import { Badge } from "../../../../components/badges/Badge";
import { T } from "../../../../components/translation/T";
import {
  MultiForm,
  MultiFormContainer,
} from "../../../../components/form/MultiForm";
import { Form } from "../../../../components/form/Form";
import { HiddenInput } from "../../../../components/form/HiddenInput";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { AnimateHeightMotion } from "../../../../components/animate/AnimateHeightMotion";
import { dataAssociates } from "../../../../data/dataAssociates";
import { useLinkId } from "../../../../hooks/useLinkId";
import { PepIntro } from "./steps/PepIntro";
import { PepRelativePosition } from "./steps/PepRelativePosition";
import { PepRelativeName } from "./steps/PepRelativeName";
import { Done } from "./steps/Done";
import { PepPosition } from "./steps/PepPosition";
import { PepDates } from "./steps/PepDates";
import { PepResidence } from "./steps/PepResidence";
import { useAtomValue } from "jotai";
import { contractState } from "../../../../state/contractState";
import { routeState } from "../../../../state/routeState";

interface Props {
  associate: Associate;
}

export const MIN_LENGTH = 2;
export const MAX_LENGTH = 30;

function hasRequiredProps(associate: Associate) {
  const { id } = associate;

  if (!id) {
    return false;
  }

  if (!id.citizenships?.length) {
    return false;
  }

  if (!id.countryOfBirth) {
    return false;
  }

  if (!id.countryOfResidence) {
    return false;
  }

  return true;
}

export const PepOverlay: React.FunctionComponent<Props> = ({ associate }) => {
  const linkId = useLinkId();
  const queryClient = useQueryClient();
  const requiredProps = useRef<boolean>(hasRequiredProps(associate));
  const [pep, setPep] = useState<Pep>({
    type: "" as PEP_TYPE,
    ...associate.pep,
  });
  const [id, setId] = useState<Id>();
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState<number>(0);
  const { access } = useAtomValue(routeState);
  const indexRef = useRef<number>(0);
  const formContainer = useRef<MultiFormContainer>(undefined);
  const ref = useRef<HTMLDivElement>(null);
  const contract = useAtomValue(contractState);
  const isViewer =
    associate.associateId === contract.contractViewer.associateId;

  const [isFormValid, setIsFormValid] = useState(false);

  const {
    mutate: onSave,
    isPending: isSaving,
    isError: isSaveError,
    reset,
  } = useMutation({
    mutationFn: () => {
      if (requiredProps.current) {
        return dataAssociates(access).saveAssociate(linkId, {
          ...associate,
          pep,
        });
      } else {
        return dataAssociates(access).saveAssociate(linkId, {
          ...associate,
          id: {
            ...associate.id,
            ...id,
          } as Id,
          pep,
        });
      }
    },

    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: dataAssociates(access).getAssociatesKey(linkId),
      });
      setIsOpen(false);
      setTimeout(() => setIndex(0), 350);
    },
  });

  const close = useCallback(() => {
    setIsOpen(false);
    setTimeout(() => {
      setIndex(0);
      reset();
    }, 350);
  }, [reset]);

  const onRetry = useCallback(() => {
    reset();
    setTimeout(onSave, 300);
  }, [reset, onSave]);

  useEffect(() => {
    if (index >= indexRef.current) {
      indexRef.current = index;
      return;
    }

    // We're going back in the overlay
    // reset errors
    reset();
    indexRef.current = index;
  }, [index, reset]);

  const headers = useMemo(() => {
    const headerArray = ["Politically Exposed Person"];
    if (!requiredProps.current) {
      headerArray.push("Residence / Country of birth");
    }

    if (pep.type === PEP_TYPE.PEP) {
      headerArray.push("Position of PEP");
      headerArray.push("Dates of assignment");
    }

    if (pep.type === PEP_TYPE.RELATIVE_TO_PEP) {
      headerArray.push("Position of / relation to PEP");
      headerArray.push("Name of PEP");
      headerArray.push("Dates of assignment");
    }

    if (pep.type === PEP_TYPE.PEP_AND_PEP_RELATIVE) {
      headerArray.push("Position of PEP");
      headerArray.push("Name of relative");
      headerArray.push("Dates of assignment");
    }

    headerArray.push("Done!");

    return headerArray;
  }, [pep.type]);

  const isPep = pep.type === PEP_TYPE.PEP;
  const isRelative = pep.type === PEP_TYPE.RELATIVE_TO_PEP;
  const isPepAndRelative = pep.type === PEP_TYPE.PEP_AND_PEP_RELATIVE;

  return (
    <div className={cx(styles.pep)} ref={ref}>
      <MultiForm
        multiFormContainer={formContainer}
        updateFormValidity={setIsFormValid}
      >
        <EditRow isValid={isFormValid} setIsOpen={setIsOpen}>
          <div className={cx(styles.info)}>
            <div className={cx(styles.description)}>
              <b>
                {associate.contact.firstName} {associate.contact.lastName}
              </b>
            </div>

            <AnimateHeightMotion presence>
              {!isFormValid && (
                <Badge truncate className="text-small">
                  <T>Please answer questions</T>
                </Badge>
              )}
            </AnimateHeightMotion>
          </div>
        </EditRow>
        <PersistentOverlay
          open={isOpen}
          onClose={close}
          className="full-height"
        >
          <Steps
            index={index}
            header={headers}
            length={headers.length}
            insideOverlay
          >
            <PepIntro
              onChange={setPep}
              pep={pep}
              onSubmit={() => setIndex(index + 1)}
              isViewer={isViewer}
            />

            {!requiredProps.current && (
              <PepResidence
                onChange={setId}
                id={id}
                pep={pep}
                onBack={() => setIndex(index - 1)}
                onSubmit={() => setIndex(index + 1)}
                isViewer={isViewer}
              />
            )}

            {(isPep || isPepAndRelative) && (
              <PepPosition
                onChange={setPep}
                pep={pep}
                onBack={() => setIndex(index - 1)}
                onSubmit={() => setIndex(index + 1)}
                isViewer={isViewer}
              />
            )}

            {isRelative && (
              <PepRelativePosition
                onChange={setPep}
                pep={pep}
                onBack={() => setIndex(index - 1)}
                onSubmit={() => setIndex(index + 1)}
                isViewer={isViewer}
              />
            )}

            {(isRelative || isPepAndRelative) && (
              <PepRelativeName
                onChange={setPep}
                pep={pep}
                onBack={() => setIndex(index - 1)}
                onSubmit={() => setIndex(index + 1)}
                isViewer={isViewer}
              />
            )}

            {(isPep || isRelative || isPepAndRelative) && (
              <PepDates
                onChange={setPep}
                pep={pep}
                onBack={() => setIndex(index - 1)}
                onSubmit={() => setIndex(index + 1)}
                isViewer={isViewer}
              />
            )}

            <Done
              setIndex={setIndex}
              index={index}
              onSave={onSave}
              onRetry={onRetry}
              isLoading={isSaving}
              isError={isSaveError}
            />
          </Steps>
        </PersistentOverlay>
      </MultiForm>

      <Form name={`pep-${associate.associateId}`}>
        <HiddenInput
          label="Pep information"
          value={isFormValid ? true : undefined}
          validators={[
            new RequiredValidator("Missing required PEP information"),
          ]}
          scrollToRef={ref}
        />
      </Form>
    </div>
  );
};
