import {
  useMutation,
  useQueryClient,
  useSuspenseQueries,
} from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";
import { AnimateHeight } from "../../../components/animate/AnimateHeight";
import { AnimateHeightMotion } from "../../../components/animate/AnimateHeightMotion";
import { GenericError } from "../../../components/Errors/GenericError";
import { Form } from "../../../components/form/Form";
import { MultiForm } from "../../../components/form/MultiForm";
import { useMultiForm } from "../../../components/form/MultiFormContext";
import { TextArea } from "../../../components/form/TextArea";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { ButtonPaneWithLabel } from "../../../components/interactions/Buttons/ButtonPaneInput";
import { Section } from "../../../components/section/Section";
import { StoryStepProps } from "../../../components/story/Story";
import { StoryContinueButton } from "../../../components/story/StoryContinueButton";
import { T } from "../../../components/translation/T";
import {
  FileUpload,
  getUploadBaseUrl,
} from "../../../components/uploads/FileUpload";
import {
  dataOwnership,
  OwnershipAndControlData,
} from "../../../data/dataOwnership";
import { useLinkId } from "../../../hooks/useLinkId";
import { OwnershipCompanies } from "./components/OwnershipCompanies";
import { ValidationErrors } from "./components/ValidationErrors";
import { useAtomValue } from "jotai";
import { routeState } from "../../../state/routeState";
import { Access } from "../../../data/proxy";

export const OwnershipAndControl: React.FunctionComponent<StoryStepProps> = ({
  next,
}) => {
  const linkId = useLinkId();
  const { access } = useAtomValue(routeState);

  const [{ data: response }] = useSuspenseQueries({
    queries: [dataOwnership(access).fetchOwnership(linkId)],
  });

  const [data, setData] = useState<OwnershipAndControlData>(response);
  const queryClient = useQueryClient();
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      controlStructureDocument: response.controlStructureDocument,
      nomineeShareholdersDocument: response.nomineeShareholdersDocument,
      shareholderCustodianDocument: response.shareholderCustodianDocument,
    }));
  }, [response]);

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: dataOwnership(access).getQueryKey(linkId),
    });
  }, [linkId, access, queryClient]);

  const {
    mutate: onSave,
    isPending: isLoading,
    isError,
    reset,
  } = useMutation({
    mutationFn: () => dataOwnership(access).postOwnership(linkId, data),
    onSuccess: () => {
      invalidateCache();
      next();
    },
  });

  useEffect(() => {
    if (!isError) {
      return;
    }

    setTimeout(() => {
      reset();
    }, 3000);
  }, [isError, reset]);

  return (
    <>
      <MultiForm>
        <h1>
          <T>Ownership & control structure</T>
        </h1>
        <Form name="Ownership" className="m-top-40">
          <ButtonPaneWithLabel
            label="Merchant is owned by other legal part?"
            value={data.ownedByOtherLegalPart}
            onChange={(value) => {
              setData({
                ...data,
                ownedByOtherLegalPart: value ?? false,
              });
            }}
            small
            buttons={[
              {
                text: "Yes",
                active: data.ownedByOtherLegalPart === true,
                data: true,
              },
              {
                text: "No",
                active: data.ownedByOtherLegalPart === false,
                data: false,
              },
            ]}
            validators={[new RequiredValidator("Required to answer")]}
          />
        </Form>
        <AnimateHeightMotion presence>
          {data.ownedByOtherLegalPart && (
            <div className="p-bottom-40">
              <OwnershipCompanies
                companies={data.companies}
                onChange={(companies) => {
                  setData({
                    ...data,
                    companies,
                  });
                }}
              />
            </div>
          )}
        </AnimateHeightMotion>

        <div className="m-top-40" />

        <Form name="ControlAndShareholders" ref={formRef}>
          <ButtonPaneWithLabel
            label="Merchant is controlled by other legal part?"
            value={data.controlledByOtherLegalPart}
            className="m-top-40"
            onChange={(value) => {
              setData({
                ...data,
                controlledByOtherLegalPart: value ?? false,
              });
            }}
            small
            buttons={[
              {
                text: "Yes",
                active: data.controlledByOtherLegalPart === true,
                data: true,
              },
              {
                text: "No",
                active: data.controlledByOtherLegalPart === false,
                data: false,
              },
            ]}
            validators={[new RequiredValidator("Required to answer")]}
          />
          <AnimateHeightMotion presence>
            {data.controlledByOtherLegalPart && (
              <Section highlighted>
                <>
                  <TextArea
                    label="Type of arrangements regulating control (e.g. nominee shareholding, statutes, shareholders agreement)"
                    value={data.controlArrangement}
                    onChange={(value) =>
                      setData({
                        ...data,
                        controlArrangement: value,
                      })
                    }
                    validators={[new RequiredValidator("Required to answer")]}
                  />
                  <div className="m-top-20">
                    <TextArea
                      label="Reason for other types of arrangements requiring regulating control"
                      value={data.controlArrangementOtherReason}
                      onChange={(value) =>
                        setData({
                          ...data,
                          controlArrangementOtherReason: value,
                        })
                      }
                    />
                  </div>
                  <p>
                    <T>Control structure attachment</T>
                  </p>
                  <FileUpload
                    endpoint={`${getUploadBaseUrl()}/api/merchant/${linkId}/ownership/control-structure-document`}
                    color="#e5e9f2"
                    onUpload={invalidateCache}
                    onRemove={invalidateCache}
                    disabled={access === Access.VIEW}
                    buttonClasses="ghost block"
                    initialFile={
                      data.controlStructureDocument.uploaded
                        ? {
                            name:
                              data.controlStructureDocument.filename ||
                              data.controlStructureDocument.fileName ||
                              "",
                            type: data.controlStructureDocument.mimeType || "",
                          }
                        : undefined
                    }
                  />
                </>
              </Section>
            )}
          </AnimateHeightMotion>

          <div className="m-top-40" />

          <ButtonPaneWithLabel
            label="Does the company direct or indirectly have or allow nominee shareholders?"
            value={data.nomineeShareholders}
            className="m-top-40"
            onChange={(value) => {
              setData({
                ...data,
                nomineeShareholders: value ?? false,
              });
            }}
            small
            buttons={[
              {
                text: "Yes",

                active: data.nomineeShareholders === true,
                data: true,
              },
              {
                text: "No",
                active: data.nomineeShareholders === false,
                data: false,
              },
            ]}
            validators={[new RequiredValidator("Required to answer")]}
          />
          <AnimateHeightMotion presence>
            {data.nomineeShareholders && (
              <Section highlighted>
                <p>
                  <T>Provide copy of records held of such shares...</T>
                </p>
                <FileUpload
                  endpoint={`${getUploadBaseUrl()}/api/merchant/${linkId}/ownership/nominee-shareholders-document`}
                  color="#e5e9f2"
                  onUpload={invalidateCache}
                  onRemove={invalidateCache}
                  disabled={access === Access.VIEW}
                  buttonClasses="ghost block"
                  initialFile={
                    data.nomineeShareholdersDocument.uploaded
                      ? {
                          name:
                            data.nomineeShareholdersDocument.filename ||
                            data.nomineeShareholdersDocument.fileName ||
                            "",
                          type: data.nomineeShareholdersDocument.mimeType || "",
                        }
                      : undefined
                  }
                />

                <div className="m-top-20">
                  <p>
                    <T>
                      ...and a letter from the custodian who holds the shares
                    </T>
                  </p>

                  <FileUpload
                    endpoint={`${getUploadBaseUrl()}/api/merchant/${linkId}/ownership/shareholder-custodian-document`}
                    color="#e5e9f2"
                    onUpload={invalidateCache}
                    onRemove={invalidateCache}
                    disabled={access === Access.VIEW}
                    buttonClasses="ghost block"
                    initialFile={
                      data.shareholderCustodianDocument.uploaded
                        ? {
                            name:
                              data.shareholderCustodianDocument.filename ||
                              data.shareholderCustodianDocument.fileName ||
                              "",
                            type:
                              data.shareholderCustodianDocument.mimeType || "",
                          }
                        : undefined
                    }
                  />
                </div>
              </Section>
            )}
          </AnimateHeightMotion>

          <div className="m-top-20">
            <ValidationErrors data={data} formRef={formRef} />
          </div>
        </Form>

        <div className="m-bottom-20">
          <div className="m-top-40">
            <AnimateHeight name={isError ? "error" : ""}>
              <div>{isError ? <GenericError /> : <div />}</div>
            </AnimateHeight>
          </div>
        </div>

        <SubmitButton onClick={onSave} access={access} isLoading={isLoading} />
      </MultiForm>
    </>
  );
};

const SubmitButton = ({
  onClick,
  isLoading,
  access,
}: {
  onClick: () => void;
  isLoading: boolean;
  access: Access;
}) => {
  const multiForm = useMultiForm();

  const handleClick = () => {
    if (!multiForm || multiForm.isInvalid) {
      multiForm?.forceValidation();
      return;
    }

    onClick();
  };

  return (
    <StoryContinueButton
      type="button"
      disabled={access === Access.VIEW}
      onClick={handleClick}
      isLoading={isLoading}
    >
      <T>Continue</T>
    </StoryContinueButton>
  );
};
