import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { generatePath } from "react-router";
import { useAtom } from "jotai";
import { ErrorBox } from "../../../components/boxes/ErrorBox";
import { Button } from "../../../components/interactions/Buttons/Button";
import { PersistentOverlay } from "../../../components/overlay/PersistantOverlay";
import { Retry } from "../../../components/retry/Retry";
import { T } from "../../../components/translation/T";
import { dataAssociates, isPrimary } from "../../../data/dataAssociates";
import { ContractData, dataMerchant } from "../../../data/dataMerchant";
import { LinkId } from "../../../data/models/ContractTypes";
import { Status } from "../../../data/types";
import { useLinkId } from "../../../hooks/useLinkId";
import { contractState } from "../../../state/contractState";
import { routeState } from "../../../state/routeState";
import { DATA_COLLECTION_STORY_ROUTES } from "../dataCollectionRoutes";
import { DATA_COLLECTION_STORY_URL } from "../DataCollectionStory";
import { ContractCell } from "./ContractCell";
import { ContractSection } from "./ContractSection";
import { Paper } from "./Paper";
import { Signatures } from "./Signatures";
import { Top } from "./Top";
import { useViewerAsAssociate } from "../../../hooks/useViewerAsAssociate";
import { Split } from "./Split";
import { FinancialData, dataFinancial } from "../../../data/dataFinancial";
import { getCountryDisplayName } from "../../../components/translation/i18nUtils";
import i18n from "../../../i18n";
import { Access } from "../../../data/proxy";
import { useSuspenseQueries } from "@tanstack/react-query";

function getIntroPath(linkId: LinkId) {
  return generatePath(
    `${DATA_COLLECTION_STORY_URL}${DATA_COLLECTION_STORY_ROUTES.INTRO.path}`,
    {
      linkId,
    }
  );
}

export const Summary: FunctionComponent = () => {
  const linkId = useLinkId();
  const [{ access }, setRouteState] = useAtom(routeState);
  const [{ data: financial }, { data: associates }] = useSuspenseQueries({
    queries: [
      dataFinancial(access).fetchFinancial(linkId),
      dataAssociates(access).fetchAssociates(linkId),
    ],
  });

  const [contract, setContract] = useAtom(contractState);
  const viewerAsAssociate = useViewerAsAssociate(
    associates,
    contract.contractViewer
  );
  const { version } = contract.contractData;
  const { contractData: data } = contract;
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setRouteState((prev) => ({ ...prev, showBack: false }));
    setContract((prev) => ({
      ...prev,
      hasShownContract: true,
    }));

    return () => {
      setRouteState((prev) => ({ ...prev, showBack: true }));
    };
  }, [setRouteState, setContract]);

  const confirm = useCallback(() => {
    dataMerchant(access)
      .confirmContract(linkId, version)
      .then(() => {
        setContract((prev) => ({
          ...prev,
          confirmedContract: true,
        }));
        setStatus(Status.SUCCESS);
      })
      .catch((error) => {
        setStatus(Status.ERROR);
        // if (error.status !== VERSION_ERROR_CODE) {
        //   setStatus(Status.ERROR);
        //   return;
        // }

        // setIsOpen(true);
      });
  }, [linkId, version, access, setContract]);

  useEffect(() => {
    if (contract.confirmedContract) {
      setStatus(Status.SUCCESS);
      return;
    }

    if (!isPrimary(viewerAsAssociate)) {
      setStatus(Status.SUCCESS);
      return;
    }

    if (access === Access.VIEW) {
      setStatus(Status.SUCCESS);
      return;
    }

    confirm();
  }, [contract.confirmedContract, access, confirm, viewerAsAssociate]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);

    setTimeout(() => {
      confirm();
    }, 500);
  }, [confirm]);

  return (
    <div>
      <PersistentOverlay
        open={isOpen}
        onClose={() => setIsOpen(false)}
        disableClose
      >
        <ErrorBox relative>
          <b>
            <T>Oh no!</T>
          </b>{" "}
          <T>This is the worst. </T>
          <p>
            <T>
              It seems like there, for some reason, have been changes to the
              contract while you where looking at the page. Perhaps we had to
              update some incorrect contract information.
            </T>
          </p>
        </ErrorBox>
        <div className="m-top-30">
          <T>
            Unfortunately, this means that you will have review the information
            again. Very sorry for this. We will try to prefill as much as
            possible
          </T>{" "}
          😔.
        </div>

        <div className="m-top-30">
          <Button
            onClick={() => {
              window.location.href = getIntroPath(linkId);
            }}
            block
          >
            <T>Reload information</T>
          </Button>
        </div>
      </PersistentOverlay>

      <Retry status={status} retry={retry}>
        <SummaryInner data={data} financial={financial}>
          <h2>
            <T>Summary</T>
          </h2>
        </SummaryInner>
      </Retry>
    </div>
  );
};

export const SummaryInner: FunctionComponent<{
  data: ContractData;
  children: ReactNode;
  financial: FinancialData;
}> = ({ children, data, financial }) => {
  return (
    <>
      {children}
      <Paper>
        <Top />

        <div className="m-top-40">
          <ContractSection name={<T>Company information</T>}>
            <Split>
              <ContractCell first name={<T>Company name</T>}>
                {data.companyName}
              </ContractCell>
              <ContractCell name={<T>Registration Identification</T>}>
                {data.organizationNumber}
              </ContractCell>
            </Split>

            <Split>
              <ContractCell first name={<T>Legal form</T>}>
                {data.legalEntityType}
              </ContractCell>
              <ContractCell name={<T>Company phone</T>}>
                {data.contactPhoneNumber}&nbsp;
              </ContractCell>
            </Split>

            <ContractCell first name={<T>Business model</T>}>
              {financial.businessModel}
            </ContractCell>

            <ContractCell
              first
              name={
                <T>Products or Services the company accepts payments for</T>
              }
            >
              {financial.productDescription}
            </ContractCell>

            <ContractCell first name={<T>Type of business</T>}>
              {data.mccIndustry}
            </ContractCell>

            <Split>
              <ContractCell first split={3 / 4} name={<T>Address</T>}>
                {data.address?.street}
              </ContractCell>
              <ContractCell split={1 / 4} name={<T>Postal code</T>}>
                {data.address?.postalCode}
              </ContractCell>
            </Split>
            <Split>
              <ContractCell first name={<T>City</T>}>
                {data.address?.city}
              </ContractCell>
              <ContractCell name={<T>Country</T>}>
                {data.address?.countryCode
                  ? getCountryDisplayName(
                      data.address?.countryCode,
                      i18n.language
                    )
                  : "-"}
              </ContractCell>
            </Split>
            {/* <ContractCell name={<T>Date of incoorperation</T>}>
                {getIntlDate(
                  data.dateOfIncooperation,
                  i18n.language as Language
                )}
              </ContractCell> */}
          </ContractSection>
        </div>

        <Signatures />
      </Paper>
    </>
  );
};
