import React, { useCallback } from "react";
import styles from "./DocumentUpload.module.scss";
import { ContractDocument, LinkId } from "../../../data/models/ContractTypes";
import {
  FileUpload,
  getUploadBaseUrl,
} from "../../../components/uploads/FileUpload";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { dataDocuments, getDescription } from "../../../data/dataDocuments";
import { Access } from "../../../data/proxy";

interface Props {
  document: ContractDocument;
  link: string;
  disabled?: boolean;
}

export const DocumentUpload: React.FunctionComponent<Props> = ({
  document,
  link,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: dataDocuments(Access.VIEW_AND_EDIT).getDocumentsKey(
        link as LinkId
      ),
    });
  }, [link, queryClient]);

  return (
    <div className={styles.documentUpload}>
      <div className={styles.desc}>{getDescription(document, t)}</div>
      <FileUpload
        endpoint={`${getUploadBaseUrl()}/api/merchant/${link}/documents/${
          document.documentId
        }`}
        onUpload={invalidateCache}
        onRemove={invalidateCache}
        disabled={disabled}
        buttonClasses="small ghost"
        initialFile={
          document.uploaded
            ? {
                name: document.filename || document.fileName || "",
                type: document.mimeType || "",
              }
            : undefined
        }
      />{" "}
    </div>
  );
};
