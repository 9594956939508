import React, { Dispatch, SetStateAction, RefObject } from "react";
import cx from "classnames";
import styles from "./SelfDeclaration.module.scss";
import {
  AppStore,
  PaymentIntegration,
  WebStore,
} from "../../data/dataMerchant";
import { HiddenInput } from "../form/HiddenInput";
import { RequiredValidator } from "../form/validators/RequiredValidator";
import { T } from "../translation/T";
import { TooltipWrapper } from "../tooltip/TooltipWrapper";
import { Trans, useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

interface Props {
  ecomStore: WebStore | AppStore;
  setWebStore?: Dispatch<SetStateAction<WebStore>>;
  setAppStore?: Dispatch<SetStateAction<AppStore>>;
  formRef: RefObject<HTMLFormElement | null>;
}

function update(
  paymentIntegration: PaymentIntegration,
  setWebStore?: Dispatch<SetStateAction<WebStore>>,
  setAppStore?: Dispatch<SetStateAction<AppStore>>
) {
  if (setWebStore) {
    setWebStore((prev) => ({
      ...prev,
      web: {
        ...prev.web,
        paymentIntegration,
      },
    }));
    return;
  }

  if (setAppStore) {
    setAppStore((prev) => ({
      ...prev,
      app: {
        ...prev.app,
        paymentIntegration,
      },
    }));
  }
}

export const SelfDeclaration: React.FunctionComponent<Props> = ({
  ecomStore,
  setWebStore,
  setAppStore,
  formRef,
}) => {
  const { t } = useTranslation();
  const active = setWebStore
    ? (ecomStore as WebStore).web.paymentIntegration
    : (ecomStore as AppStore).app.paymentIntegration;

  return (
    <>
      <Tooltip
        anchorSelect=".tooltip"
        content={t("Specify your specific gateway solution")}
      />

      <h5>
        <Trans t={t}>
          Self declaration -{" "}
          <TooltipWrapper selector="tooltip">payment channel</TooltipWrapper>
        </Trans>
      </h5>

      <ul className="m-top-20">
        <li
          className={cx(styles.item, {
            [styles.active]: active === PaymentIntegration.HOSTED,
          })}
        >
          <button
            onClick={(ev) => {
              ev.preventDefault();
              update(PaymentIntegration.HOSTED, setWebStore, setAppStore);
            }}
          >
            <h5>
              <T>E-com Hosted (fully outsourced)</T>
            </h5>
            <div className={cx("text-small", [styles.info])}>
              <div className={styles.iconWrapper}>
                <div
                  className={cx(styles.tickbox, {
                    [styles.selected]: active === PaymentIntegration.HOSTED,
                  })}
                />
              </div>
              <T>
                This solution uses the regular redirect (or overlay) and iframe.
                Prefills data by PSP. PCI DSS validation is optional.
              </T>
            </div>
          </button>
        </li>
        <li
          className={cx(styles.item, {
            [styles.active]: active === PaymentIntegration.SEMI,
          })}
        >
          <button
            onClick={(ev) => {
              ev.preventDefault();
              update(PaymentIntegration.SEMI, setWebStore, setAppStore);
            }}
          >
            <h5>
              <T>E-com Semi hosted</T>
            </h5>
            <div className={cx("text-small", [styles.info])}>
              <div className={styles.iconWrapper}>
                <div
                  className={cx(styles.tickbox, {
                    [styles.selected]: active === PaymentIntegration.SEMI,
                  })}
                />
              </div>
              <T>
                Card data has to be manually inserted by the merchant before
                sent to PSP. Higher risk than the fully outsourced solution.
                Mandatory PCI DSS validation by SAQ A EP or SAQ D.
              </T>
            </div>
          </button>
        </li>
        <li
          className={cx(styles.item, {
            [styles.active]: active === PaymentIntegration.API,
          })}
        >
          <button
            onClick={(ev) => {
              ev.preventDefault();
              update(PaymentIntegration.API, setWebStore, setAppStore);
            }}
          >
            <h5>
              <T>E-com API integrated</T>
            </h5>
            <div className={cx("text-small", [styles.info])}>
              <div className={styles.iconWrapper}>
                <div
                  className={cx(styles.tickbox, {
                    [styles.selected]: active === PaymentIntegration.API,
                  })}
                />
              </div>
              <T>
                Solution that handles card data, data storage and processing. A
                data breach is more likely. Mandatory PCI DSS validation by SAQ
                D.
              </T>
            </div>
          </button>
        </li>
      </ul>

      <HiddenInput
        label="Add 3rd party payment"
        value={active ? true : undefined}
        validators={[
          new RequiredValidator(
            "Please select the option that describes your solution"
          ),
        ]}
        scrollToRef={formRef}
      />
    </>
  );
};
