import { API } from "../network/API";
import { Country, KlarnaToken, LinkId } from "./models/ContractTypes";
import { Access, getProxy } from "./proxy";

interface KlarnaInitResponse {
  state: KlarnaState;
  clientToken: KlarnaToken;
}

export enum OwnedBy {
  PERSON = "PERSON",
  COMPANY = "COMPANY",
}

export interface KlarnaError {
  category: string;
  message?: string;
  reason?: Error;
}

export enum KlarnaState {
  PROCESSING = "PROCESSING",
  CONSUMER_INPUT_NEEDED = "CONSUMER_INPUT_NEEDED",
  ABORTED = "ABORTED",
  EXCEPTION = "EXCEPTION",
  FINISHED = "FINISHED",
}

export interface KlarnaPollResponse {
  state: KlarnaState;
  clientToken?: KlarnaToken;
  accounts?: KlarnaAccount[];
}

export enum KlarnaAccountType {
  DEFAULT = "DEFAULT",
  SAVING = "SAVING",
  CREDITCARD = "CREDITCARD",
  DEPOT = "DEPOT",
  UNKNOWN = "UNKNOWN",
}

export interface KlarnaAccount {
  iban?: string;
  accountName?: string;
  accountHolder?: string;
  accountType: KlarnaAccountType;
  accountStatus:
    | "MATCH"
    | "NO_MATCH"
    | "NO_HOLDER"
    | "NO_IBAN"
    | "INVALID_TYPE"
    | "INVALID_CURRENCY";
}

export interface ThirdPartyAccount {
  ibanOrAccountNumber: string;
  accountHolder: string;
  swiftOrBic: string;
}

export interface KarRequest {
  bankAccount: string;
  owner: "PERSON" | "COMPANY";
}

export interface KarResult {
  success: boolean;
}

export enum BankAccountSource {
  BANK_STATEMENT = "BANK_STATEMENT",
  OFFICIAL_REGISTRY = "OFFICIAL_REGISTRY",
  PSD_LOOKUP = "PSD_LOOKUP",
  BANKGIRO = "BANKGIRO",
}

export const bankAccountSourceText = {
  [BankAccountSource.BANKGIRO]: "Will be verified by bankgiro",
  [BankAccountSource.OFFICIAL_REGISTRY]:
    "Was verified by the official registry",
  [BankAccountSource.PSD_LOOKUP]: "Was verified by bank account lookup",
  [BankAccountSource.BANK_STATEMENT]: "Will be verifed by bank statement",
};

export interface ConfirmedStatus {
  done: boolean;
  bankAccount?: string;
  source?: BankAccountSource;
  iban?: string;
  bic?: string;
}

export enum BankType {
  PERSONAL = "personal",
  BUSINESS = "business",
}

export interface BankItem {
  name: string;
  logoUrl: string;
}

export enum WhitelistedEndpoint {
  FETCH_ACCOUNTS = "fetchAccounts",
  GET_ACCOUNTS = "getAccounts",
  GET_ACCOUNTS_KEY = "getAccountsKey",
  FETCH_BANKS = "fetchBanks",
  GET_BANKS = "getBanks",
  GET_BANKS_KEY = "getBanksKey",
  GET_JS = "getKontonummerJs",
  GET_CONFIRMED_STATUS = "getConfirmedStatus",
  GET_CONFIRMED_STATUS_KEY = "getConfirmedStatusKey",
  FETCH_CONFIRMED_STATUS = "fetchConfirmedStatus",
  GET_THIRD_PARTY_ACCOUNT = "getThirdPartyAccount",
  GET_THIRD_PARTY_ACCOUNT_KEY = "getThirdPartyAccountKey",
  FETCH_THIRD_PARTY_ACCOUNT = "fetchThirdPartyAccount",
}

export enum RoaringeAccountType {
  CACC, // Account used to post debits and credits when
  // no specific account has been nominated
  CARD, // Account used for card payments only
  CASH, // Account used for the payment of cash
  OTHR, // Account not otherwise specified
}

export interface RoaringAccount {
  iban: string;
  accountName: string;
  accountType: RoaringeAccountType;
  accountOwner: string;
  valid: boolean;
}

export interface AccountListResponse {
  completed: boolean; //False means that the login hasnt been succesful
  bank: string; //Display name of the bank
  accounts: RoaringAccount[];
}

export const Bank = {
  [WhitelistedEndpoint.GET_ACCOUNTS_KEY]: (linkId: LinkId, code?: string) => {
    return ["banks-key", linkId, code];
  },

  [WhitelistedEndpoint.GET_ACCOUNTS]: (linkId: LinkId, code?: string) => {
    if (code) {
      return API.get<AccountListResponse>(
        `/api/merchant/${linkId}/roaring/accounts?code=${code}`
      );
    }

    return API.get<AccountListResponse>(
      `/api/merchant/${linkId}/roaring/accounts`
    );
  },

  selectRoaringAccount: (linkId: LinkId, iban: string) =>
    API.post(`/api/merchant/${linkId}/roaring/select`, {
      iban,
    }),

  [WhitelistedEndpoint.FETCH_ACCOUNTS]: (
    linkId: LinkId,
    code?: string,
    allowMissing?: boolean
  ) => ({
    queryKey: Bank[WhitelistedEndpoint.GET_ACCOUNTS_KEY](linkId, code),
    queryFn: () => Bank[WhitelistedEndpoint.GET_ACCOUNTS](linkId, code),
  }),

  [WhitelistedEndpoint.GET_BANKS_KEY]: (
    linkId: LinkId,
    countryCode: Country,
    bankType: BankType
  ) => {
    return ["banks-key", linkId, countryCode, bankType];
  },

  [WhitelistedEndpoint.GET_BANKS]: (
    linkId: LinkId,
    countryCode: Country,
    bankType: BankType
  ) =>
    API.get<BankItem[]>(
      `/api/merchant/${linkId}/roaring/banks?countryCode=${countryCode}&bankType=${bankType}`
    ),

  [WhitelistedEndpoint.FETCH_BANKS]: (
    linkId: LinkId,
    countryCode: Country,
    bankType: BankType
  ) => ({
    queryKey: Bank[WhitelistedEndpoint.GET_BANKS_KEY](
      linkId,
      countryCode,
      bankType
    ),
    queryFn: () =>
      Bank[WhitelistedEndpoint.GET_BANKS](linkId, countryCode, bankType),
  }),

  initRoaring: (
    linkId: LinkId,
    bank: string,
    returnUrl: string,
    bankType: BankType,
    countryCode: Country
  ) =>
    API.post<{ url: string }>(`/api/merchant/${linkId}/roaring/start`, {
      bank,
      returnUrl,
      bankType,
      countryCode,
    }),

  // [WhitelistedEndpoint.GET_KLARNA_SDK]: (): QueryOptions<any> => ({
  //   queryKey: ["klarna"],
  //   queryFn: () => {
  //     if (window.XS2A) {
  //       return Promise.resolve(window.XS2A);
  //     }

  //     if (process.env.REACT_APP_STATIC) {
  //       return window.scripts
  //         .importScript(KLARNA_SCRIPT_URI)
  //         .then(() => window.XS2A);
  //     }

  //     return window.scripts
  //       .importScript("/libs/xs2a-app-launcher.js")
  //       .then(() => window.XS2A);
  //   },
  // }),

  initKlarna: (linkId: LinkId) =>
    API.post<KlarnaInitResponse>(`/api/merchant/${linkId}/klarna`),

  pollForClientAccounts: (linkId: LinkId) =>
    API.get<KlarnaPollResponse>(`/api/merchant/${linkId}/klarna`),

  deleteSession: (linkId: LinkId) =>
    API.delete(`/api/merchant/${linkId}/klarna`),

  selectAccount: (linkId: LinkId, iban: string) => {
    return API.post(`/api/merchant/${linkId}/klarna/bank-account`, {
      iban,
    });
  },

  requestKARVerification: (linkId: LinkId, request: KarRequest) =>
    API.post<KarResult>(`/api/merchant/bank/${linkId}/norway`, request),

  reqeustBankgiroVerification: (linkId: LinkId, bankAccount: string) =>
    API.post(`/api/merchant/bank/${linkId}/sweden`, {
      bankAccount,
    }),

  reqeustBankgiroVerificationV2: (
    linkId: LinkId,
    clearingNumber: string,
    accountNumber: string
  ) =>
    API.post(`/api/merchant/bank/${linkId}/validate/sweden`, {
      clearingNumber,
      accountNumber,
    }),

  [WhitelistedEndpoint.GET_JS]: () => {
    if (window.kontonummer) {
      return Promise.resolve(window.kontonummer);
    }

    return window.scripts.importScript("/libs/kontonummer.min.js");
  },

  [WhitelistedEndpoint.GET_CONFIRMED_STATUS_KEY]: (linkId: LinkId) => {
    return ["bank-confirmed", linkId];
  },

  [WhitelistedEndpoint.GET_CONFIRMED_STATUS]: (linkId: LinkId) =>
    API.get<ConfirmedStatus>(`/api/merchant/bank/${linkId}`),

  [WhitelistedEndpoint.FETCH_CONFIRMED_STATUS]: (linkId: LinkId) => ({
    queryKey: Bank.getConfirmedStatusKey(linkId),
    queryFn: () => Bank.getConfirmedStatus(linkId),
  }),

  [WhitelistedEndpoint.GET_THIRD_PARTY_ACCOUNT]: (linkId: LinkId) =>
    API.get<ThirdPartyAccount>(`/api/merchant/bank/${linkId}/external`),

  [WhitelistedEndpoint.FETCH_THIRD_PARTY_ACCOUNT]: (linkId: LinkId) => ({
    queryKey: Bank.getThirdPartyAccountKey(linkId),
    queryFn: () => Bank.getThirdPartyAccount(linkId),
  }),

  [WhitelistedEndpoint.GET_THIRD_PARTY_ACCOUNT_KEY]: (linkId: LinkId) => {
    return ["third-party-account", linkId];
  },

  saveThirdPartyAccount: (
    linkId: LinkId,
    thirdPartyAccount: ThirdPartyAccount
  ) => API.post(`/api/merchant/bank/${linkId}/external`, thirdPartyAccount),

  saveKarAccount: (linkId: LinkId, bankAccount: string, ownedBy: OwnedBy) => {
    return API.post(`/api/merchant/bank/${linkId}/norway`, {
      bankAccount,
      owner: ownedBy,
    });
  },

  // POST /api/merchant/{linkId}/bank-statement kan uploadas fler gånger så
  // länge som inte kontraktet inte är konfirmat
};

let bank: typeof Bank;

export function dataBank(access: Access) {
  if (!bank) {
    bank = getProxy(Bank, access, Object.values(WhitelistedEndpoint));
  }

  return bank;
}
