import { ReactNode, useMemo, useRef } from "react";
import cx from "classnames";
import { Route, useNavigate } from "react-router-dom";
import { StoryPage } from "../../components/story/StoryPage";
import { motion } from "framer-motion";
import { LegalEntityType, ProductType } from "../../data/models/ContractTypes";
import { routeState, RouteState } from "../../state/routeState";
import {
  DATA_COLLECTION_STORY_ROUTES,
  RouteConfig,
} from "./dataCollectionRoutes";
import styles from "./useDataCollectionRoutes.module.scss";
import { useAtomValue, useSetAtom } from "jotai";

export enum Direction {
  FORWARDS,
  BACKWARDS,
}

export const MOTION_VARIANTS = {
  initial: ({ direction }: { direction: Direction }) => {
    return {
      left: direction === Direction.BACKWARDS ? "-100%" : "100%",
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    };
  },
  in: {
    left: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  out: ({ direction }: { direction: Direction }) => ({
    left: direction === Direction.BACKWARDS ? "100%" : "-100%",
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  }),
};

export const useDataCollectionRoutes = (
  legalEntityType: LegalEntityType,
  productType: ProductType,
  state: RouteState
) => {
  const navigate = useNavigate();
  const setBack = useSetAtom(routeState);

  const routeConfigs: Record<string, RouteConfig> = useMemo(
    () =>
      Object.entries(DATA_COLLECTION_STORY_ROUTES).reduce(
        (
          acc,
          [key, { includeLegalEntityType, includeProductType, includeCriteria }]
        ) => {
          return includeLegalEntityType[legalEntityType] &&
            includeProductType[productType] &&
            includeCriteria(state)
            ? { ...acc, [key]: DATA_COLLECTION_STORY_ROUTES[key] }
            : acc;
        },
        {}
      ),
    [legalEntityType, productType, state]
  );

  const routes: Record<string, string> = useMemo(
    () =>
      Object.values(routeConfigs).reduce(
        (acc, { path }) => ({ ...acc, [path]: path }),
        {}
      ),
    [routeConfigs]
  );

  const routeElements = useMemo(() => {
    const routeArray = Object.values(routeConfigs);
    return (
      <>
        {routeArray.map((config, index) => (
          <Route
            key={config.path}
            path={config.path}
            element={
              <StoryRoute
                {...config}
                next={() => {
                  setBack((prev) => ({ ...prev, goingBack: false }));
                  setTimeout(() => {
                    window.requestAnimationFrame(() => {
                      navigate(routeArray[index + 1]?.path);
                    });
                  }, 0);
                }}
              />
            }
          />
        ))}
      </>
    );
  }, [navigate, setBack, routeConfigs]);

  return { routes, routeElements };
};

const StoryRoute = ({
  element: Element,
  next,
}: RouteConfig & { next: () => void }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { goingBack } = useAtomValue(routeState);

  return (
    <Wrapper direction={goingBack ? Direction.BACKWARDS : Direction.FORWARDS}>
      <StoryPage ref={ref}>
        <Element next={next} pageRef={ref} />
      </StoryPage>
    </Wrapper>
  );
};

const Wrapper: React.FunctionComponent<{
  children: ReactNode;
  direction: Direction;
}> = ({ children, direction }) => {
  return (
    <motion.div
      className={cx(styles.storyPage)}
      custom={{
        direction,
      }}
      initial="initial"
      animate="in"
      exit="out"
      variants={MOTION_VARIANTS}
      onAnimationComplete={() => {
        setTimeout(() => {
          requestAnimationFrame(() => {
            const item = document.querySelector(".content-wrapper");
            item?.scrollTo({ top: 0, behavior: "smooth" });
          });
        }, 0);
      }}
    >
      {children}
    </motion.div>
  );
};
