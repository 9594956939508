import { API } from "../network/API";
import { Store, EcomStore } from "./dataMerchant";
import { LinkId } from "./models/ContractTypes";
import { Access, getProxy } from "./proxy";

const BASE_PATH = "/api/merchant";

enum WhitelistedEndpoint {
  GET_STORES = "getStores",
  GET_ECOM_STORE = "getEcomStore",
  FETCH_STORES = "fetchStores",
  FETCH_ECOM_STORE = "fetchEcomStore",
  GET_STORES_KEY = "getStoresKey",
  GET_ECOM_STORE_KEY = "getEcomStoresKey",
}

export const Stores = {
  [WhitelistedEndpoint.GET_STORES]: (linkId: LinkId) =>
    API.get<Store[]>(`${BASE_PATH}/${linkId}/stores`),

  [WhitelistedEndpoint.GET_ECOM_STORE]: (linkId: LinkId) =>
    API.get<EcomStore>(`${BASE_PATH}/${linkId}/ecomstore`),

  [WhitelistedEndpoint.FETCH_STORES]: (linkId: LinkId) => ({
    queryKey: Stores.getStoresKey(linkId),
    queryFn: () => Stores.getStores(linkId),
  }),

  [WhitelistedEndpoint.FETCH_ECOM_STORE]: (linkId: LinkId) => ({
    queryKey: Stores.getEcomStoresKey(linkId),
    queryFn: () => Stores.getEcomStore(linkId),
  }),

  [WhitelistedEndpoint.GET_STORES_KEY]: (linkId: LinkId) => {
    return ["stores", linkId];
  },

  [WhitelistedEndpoint.GET_ECOM_STORE_KEY]: (linkId: LinkId) => {
    return ["ecom", linkId];
  },

  saveEcomStore: (linkId: LinkId, ecomStore: EcomStore) =>
    API.post<EcomStore>(`${BASE_PATH}/${linkId}/ecomstore`, ecomStore),
};

let stores: typeof Stores;

export function dataStores(access: Access) {
  if (!stores) {
    stores = getProxy(Stores, access, Object.values(WhitelistedEndpoint));
  }

  return stores;
}
