import {
  Country,
  EcomType,
  LegalEntityType,
} from "./../data/models/ContractTypes";
import { Associate, AssociateRole } from "./../data/dataMerchant";
import { atom } from "jotai";
import { ProductType } from "../data/models/ContractTypes";
import { Access } from "../data/proxy";

const TOTAL_NBR_OF_SHARES = 100;

export function onlyDirectOwnersWithFullOwnership(associates: Associate[]) {
  const owners = associates.filter((associate) =>
    associate.roles.includes(AssociateRole.BENEFICIAL_OWNER)
  );

  const hasIndirectOwners = owners.some(
    (owner) => !!owner.owner?.hasIndirectOwnership
  );

  if (hasIndirectOwners) {
    return false;
  }

  const totalShares = owners.reduce((acc, curr) => {
    return acc + (curr.owner?.capitalStake ?? 0);
  }, 0);

  // const totalVotingRights = owners.reduce((acc, curr) => {
  //   return acc + (curr.owner?.votingRightsStake ?? 0);
  // }, 0);

  return totalShares === TOTAL_NBR_OF_SHARES;
}

export interface RouteState {
  access: Access;
  linkId?: string;
  hasGenericDocuments: boolean;
  hasSimpleOwnership: boolean;
  accountHolderIsPrimaryContact: boolean;
  payoutAccountHasBeenAdded: boolean;
  hasSelfCheckout: boolean;
  showBack: boolean;
  legalEntityType: LegalEntityType;
  productType: ProductType;
  ecomType: EcomType;
  thirdPartyPayments?: boolean;
  country: Country;
  everyAssociateHaveId: boolean;
  viewOnly?: boolean;
  roaring?: boolean;
  goingBack: boolean;
}

export const routeState = atom<RouteState>({
  access: Access.VIEW_AND_EDIT,
  hasGenericDocuments: false,
  hasSimpleOwnership: false,
  accountHolderIsPrimaryContact: true,
  payoutAccountHasBeenAdded: false,
  hasSelfCheckout: false,
  showBack: true,
  legalEntityType: LegalEntityType.LIMITED,
  productType: ProductType.BAMBORA_ONE,
  ecomType: EcomType.APP,
  thirdPartyPayments: undefined,
  country: Country.SWEDEN,
  everyAssociateHaveId: false,
  viewOnly: false,
  roaring: undefined,
  goingBack: false,
});
