import React, { useState, useEffect, useCallback } from "react";
import { T } from "../../../../components/translation/T";
import { StoryStepProps } from "../../../../components/story/Story";
import { dataAssociates } from "../../../../data/dataAssociates";
import { useLinkId } from "../../../../hooks/useLinkId";
import { useAccess } from "../../../../hooks/useAccess";
import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query";
import {
  filterOwners,
  filterSignees,
} from "../signatoryCombinations/SignatoriesFromCombinations";
import { Associate, AssociateRole } from "../../../../data/dataMerchant";
import { Access } from "../../../../data/proxy";
import { StoryContinueButton } from "../../../../components/story/StoryContinueButton";
import { SignatoryOverlay } from "../components/SignatoryOverlay";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Trans, useTranslation } from "react-i18next";
import { id as generateId } from "../../../../components/utils";
import { AssociateId } from "../../../../data/models/ContractTypes";
import styles from "./SignatoriesMissing.module.scss";
import { NewSignatory } from "./NewSignatory";
import {
  SelectableCard,
  SelectableType,
} from "../../../../components/cards/SelectableCard";
import { MultiForm } from "../../../../components/form/MultiForm";
import { useMultiForm } from "../../../../components/form/MultiFormContext";
import { Dynamic } from "../../../../components/animate/Dynamic";
import { GenericError } from "../../../../components/Errors/GenericError";
import { Form } from "../../../../components/form/Form";
import { HiddenInput } from "../../../../components/form/HiddenInput";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";

function isAlsoSignee(owner: Associate, signees: Associate[]) {
  return !!signees.find((signee) => signee.associateId === owner.associateId);
}

function isSelectedSignatory(signee: Associate) {
  return (
    signee.roles.includes(AssociateRole.SELECTED_SIGNATORY) &&
    signee.roles.includes(AssociateRole.SIGNATORY)
  );
}

function removeAll(roles: AssociateRole[]) {
  return roles.filter((role) => {
    if (role === AssociateRole.SIGNATORY) {
      return false;
    }

    if (role === AssociateRole.SELECTED_SIGNATORY) {
      return false;
    }

    return true;
  });
}

function addAll(roles: AssociateRole[]) {
  return [
    ...removeAll(roles),
    AssociateRole.SIGNATORY,
    AssociateRole.SELECTED_SIGNATORY,
  ];
}

function getSelectedSignees(associates: Associate[]) {
  return associates
    .filter((associate) =>
      associate.roles.includes(AssociateRole.SELECTED_SIGNATORY)
    )
    .map((associate) => associate.associateId);
}

export const SignatoriesMissing: React.FunctionComponent<StoryStepProps> = ({
  next,
}) => {
  const { t } = useTranslation();
  const access = useAccess();
  const linkId = useLinkId();
  const [signees, setSignees] = useState<Associate[]>([]);
  const [owners, setOwners] = useState<Associate[]>([]);
  const [idOfNewSignee, setIdOfNewSignee] = useState<string | null>(null);
  const ref = React.useRef<HTMLDivElement>(null);
  const [{ data: associates = [] }] = useQueries({
    queries: [dataAssociates(access).fetchAssociates(linkId)],
  });

  const [selectedSignees, setSelectedSignees] = useState<AssociateId[]>(
    getSelectedSignees(associates)
  );
  const queryClient = useQueryClient();

  useEffect(() => {
    // fugly, prevent visual artifact
    setTimeout(() => {
      const currentSignees = filterSignees(associates);
      setSignees(currentSignees);
      const currentOwners = filterOwners(associates);

      setSelectedSignees(getSelectedSignees(associates));

      setOwners(
        currentOwners.filter((owner) => {
          if (isAlsoSignee(owner, currentSignees)) {
            return false;
          }

          return true;
        })
      );
    }, 180);
  }, [associates]);

  const {
    mutate: onSave,
    isPending: isLoading,
    isError,
  } = useMutation({
    mutationFn: () => {
      const promises: Promise<void | Associate>[] = [];
      let roles = [];

      associates.forEach((associate) => {
        const isSelected = selectedSignees.includes(associate.associateId);

        if (isSelected && isSelectedSignatory(associate)) {
          return;
        }

        if (
          !isSelected &&
          !associate.roles.includes(AssociateRole.SELECTED_SIGNATORY)
        ) {
          return;
        }

        if (isSelected) {
          roles = addAll(associate.roles);
        } else {
          roles = removeAll(associate.roles);
        }

        promises.push(
          dataAssociates(access).saveAssociate(linkId, {
            ...associate,
            roles,
          })
        );
      });

      return Promise.all(promises);
    },

    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: dataAssociates(access).getAssociatesKey(linkId),
      });
      next();
    },
  });

  const addSignatory = useCallback(() => {
    const id = generateId() as AssociateId;
    setIdOfNewSignee(id);
  }, []);

  const toggle = useCallback((associate: Associate) => {
    const { associateId } = associate;
    setSelectedSignees((prev) => {
      if (prev.includes(associateId)) {
        return prev.filter((id) => id !== associateId);
      } else {
        return [...prev, associateId];
      }
    });
  }, []);

  return (
    <div>
      <MultiForm>
        <h2>
          <T>Signatories</T>
        </h2>
        <p>
          <Trans t={t}>
            Please add <b>all signatories</b> required to sign the agreement.
            There might be multiple signatories.
          </Trans>
        </p>

        {signees.map((signee) => {
          const active = selectedSignees.includes(signee.associateId);

          return (
            <div className="m-bottom-20" key={signee.associateId}>
              <SelectableCard
                onClick={toggle}
                active={active}
                data={signee}
                className={styles.card}
                type={SelectableType.CHECKBOX}
              >
                <SignatoryOverlay
                  signatory={signee}
                  onChange={(a) => {}}
                  render={active}
                />
              </SelectableCard>
            </div>
          );
        })}

        {owners.map((signee) => {
          const active = selectedSignees.includes(signee.associateId);

          return (
            <div className="m-bottom-20" key={signee.associateId}>
              <SelectableCard
                onClick={toggle}
                active={active}
                data={signee}
                className={styles.card}
                type={SelectableType.CHECKBOX}
              >
                <SignatoryOverlay
                  signatory={signee}
                  onChange={() => {}}
                  render={active}
                />
              </SelectableCard>
            </div>
          );
        })}

        <div className="m-bottom-20">
          <NewSignatory
            id={idOfNewSignee}
            setId={setIdOfNewSignee}
            associates={associates}
            onChange={() => {}}
          />
        </div>

        <Button ghost block className="m-top-40" onClick={addSignatory}>
          <T>Add signatory</T>
        </Button>

        <div ref={ref} className="m-top-20">
          <Form name="signatoryValidation">
            <HiddenInput
              label="Missing signatories"
              value={selectedSignees.length ? true : undefined}
              validators={[
                new RequiredValidator("There must be at least one signatory"),
              ]}
              scrollToRef={ref}
            />
          </Form>
        </div>

        <div style={{ padding: "1px 0" }}>
          <Dynamic name={isError ? "error" : ""}>
            <div>
              {isError && (
                <div className={styles.error}>
                  <GenericError />
                </div>
              )}
            </div>
          </Dynamic>
        </div>
        <div className="m-top-20">
          <SubmitButton
            isLoading={isLoading}
            onClick={onSave}
            access={access}
          />
        </div>
      </MultiForm>
    </div>
  );
};

const SubmitButton = ({
  onClick,
  isLoading,
  access,
}: {
  onClick: () => void;
  isLoading: boolean;
  access: Access;
}) => {
  const multiForm = useMultiForm();

  const handleClick = () => {
    if (!multiForm || multiForm.isInvalid) {
      multiForm?.forceValidation();
      return;
    }

    onClick();
  };

  return (
    <StoryContinueButton
      type="button"
      disabled={access === Access.VIEW}
      onClick={handleClick}
      isLoading={isLoading}
    >
      <T>Continue</T>
    </StoryContinueButton>
  );
};
