import { useAtomValue } from "jotai";
import { T } from "../../../../../components/translation/T";
import { Country } from "../../../../../data/models/ContractTypes";
import { useLinkId } from "../../../../../hooks/useLinkId";
import { contractState } from "../../../../../state/contractState";

import "./SalesContact.scss";

const info: Record<
  Country,
  {
    email: string;
    phoneNumber: string;
  }
> = {
  [Country.SWEDEN]: {
    email: "sales.sweden@worldline.com",
    phoneNumber: "010-10 66 000",
  },
  [Country.NORWAY]: {
    email: "sales.norway@worldline.com",
    phoneNumber: "+47 21 93 95 90",
  },
  [Country.DENMARK]: {
    email: "sales.denmark@worldline.com",
    phoneNumber: "+45 78794700",
  },
  [Country.FINLAND]: {
    email: "myynti@worldline.com",
    phoneNumber: "+358 9 31582555",
  },
  [Country.GB]: {
    email: "support@bambora.com",
    phoneNumber: "010-10 66 000",
  },
};

export function SalesContact() {
  const linkId = useLinkId();
  const contract = useAtomValue(contractState);
  const { country } = contract.contractData;
  const { email, phoneNumber } = info[country];
  const mailTo = `mailto:${email}`;
  const tel = `tel:${phoneNumber}`;

  if (!linkId) {
    return null;
  }

  return (
    <div className="sales-contact">
      <T>If the information is incorrect, please contact get back to us at:</T>
      <br />
      <div className="m-top-10" />
      <dl className="sales-contact-flex">
        <dt>
          <T>Email</T>:
        </dt>
        <dd className="truncate">
          <a href={mailTo}>{email}</a>
        </dd>
        {phoneNumber && (
          <>
            <dt>
              <T>Phone</T>:
            </dt>
            <dd className="truncate">
              <a href={tel}>{phoneNumber}</a>
            </dd>
          </>
        )}
      </dl>
    </div>
  );
}
