import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Card } from "../../../../components/cards/Card";
import {
  Address,
  Country,
  Language,
} from "../../../../data/models/ContractTypes";
import { TRANSLATION_NAMESPACE } from "../../../../i18n";
import { T } from "../../../../components/translation/T";
import { getCountryDisplayName } from "../../../../components/translation/i18nUtils";
import { useAtomValue } from "jotai";
import { contractState } from "../../../../state/contractState";
import { DataList } from "../../../../components/datalist/DataList";
import { DataListItem } from "../../../../components/datalist/DataListItem";
import { FormattedCompanyIdName } from "../../../../components/companyIdName/CompanyIdName";
import styles from "./CompanyInfo.module.scss";
import { AddressWithSearch } from "../../../../components/address/AddressWithSearch";
import { Flag } from "../../../../components/flags/Flag";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Edit } from "../../../../components/icons/Edit";
import { AnimateHeight } from "../../../../components/animate/AnimateHeight";
import { SaveAddress } from "../../../../data/dataMerchant";
import { TextInput } from "../../../../components/form/TextInput";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { getCountryCodeFromPhoneNumber } from "../../../../components/form/validators/PhoneToCountryCode";
import { MinLengthValidator } from "../../../../components/form/validators/MinLengthValidator";
import { MaxLengthValidator } from "../../../../components/form/validators/MaxLengthValidator";
import {
  RegexValidator,
  PHONE_REGEX,
} from "../../../../components/form/validators/RegexValidator";
import { PhoneCountryCodeValidator } from "../../../../components/form/validators/PhoneCountryCodeValidator";

const TranslateMCC: Record<Language, boolean> = {
  [Language.ENGLISH]: false,
  [Language.SWEDISH]: false,
  [Language.DANISH]: false,
  [Language.NORWEGIAN]: false,
  [Language.FINNISH]: false,
};

export const MIN_MOBILE_PHONE_CHARS = 8;
export const MAX_MOBILE_PHONE_CHARS = 18;

interface Props {
  address: SaveAddress;
  onChange: Dispatch<SetStateAction<SaveAddress>>;
}

export const CompanyInfo: React.FunctionComponent<Props> = ({
  address,
  onChange,
}) => {
  const { contractData } = useAtomValue(contractState);
  const [editAddress, setEditAddress] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const lang = i18n.language as Language;

  const onAddressChange = useCallback(
    (address: Address) => {
      onChange((prev) => ({
        ...prev,
        address,
      }));
    },
    [onChange]
  );

  const flag = useMemo(() => {
    const countryCode = getCountryCodeFromPhoneNumber(
      address.contactPhoneNumber
    );

    if (countryCode) {
      return <Flag country={countryCode as Country} rounded height={16} />;
    }

    return null;
  }, [address.contactPhoneNumber]);

  const mccIndustry =
    TranslateMCC[lang] && contractData.mccIndustry
      ? t(contractData.mccIndustry, { ns: TRANSLATION_NAMESPACE.MCC })
      : contractData.mccIndustry;

  return (
    <Card accent header={<T>Company information</T>}>
      <div className={styles.row}>
        <DataList type="horizontal" align="left">
          <DataListItem
            label={<FormattedCompanyIdName country={contractData.country} />}
            value={contractData.organizationNumber}
          />
        </DataList>
      </div>
      <div className={styles.row}>
        <DataList type="horizontal" align="left">
          <DataListItem
            label="Country"
            value={getCountryDisplayName(contractData.country, i18n.language)}
          />
        </DataList>
      </div>

      <div className={styles.row}>
        <DataList type="horizontal" align="left">
          <DataListItem label="Industry" value={mccIndustry} />
        </DataList>
      </div>

      <div className="m-top-30">
        <TextInput
          label={
            <>
              <T>Company name</T>
            </>
          }
          onChange={(value) =>
            onChange((prev) => ({
              ...prev,
              companyName: value,
            }))
          }
          value={address.companyName}
          validators={[new RequiredValidator("Company name is required")]}
        />
      </div>

      <div className="m-top-20">
        <TextInput
          label={
            <>
              <T>Company phone</T> &nbsp;{flag}
            </>
          }
          onChange={(value) =>
            onChange((prev) => ({
              ...prev,
              contactPhoneNumber: value,
            }))
          }
          type="tel"
          placeholder='With country prefix e.g "+46..."'
          value={address.contactPhoneNumber}
          hint='Country prefix e.g. "+46..." must be included'
          validators={[
            new RequiredValidator("Phone number is required"),
            new PhoneCountryCodeValidator(
              "Phone number must start with a country code e.g +61..."
            ),
            new RegexValidator(
              PHONE_REGEX,
              "Phone contains invalid characters"
            ),
            new MinLengthValidator(
              MIN_MOBILE_PHONE_CHARS,
              `Mobile phone must be at least ${MIN_MOBILE_PHONE_CHARS} characters`
            ),
            new MaxLengthValidator(
              MAX_MOBILE_PHONE_CHARS,
              `Mobile phone must be less than ${MAX_MOBILE_PHONE_CHARS} characters`
            ),
          ]}
        />
      </div>

      {/* <Products productType={productType}>
        <Products.Product
          products={[ProductType.CHECKOUT, ProductType.ACCEPTANCE_ONLINE]}
        >
          <div className="m-top-20">
            <TextInput
              label="Website"
              onChange={(value) =>
                onChange((prev) => ({
                  ...prev,
                  website: value,
                }))
              }
              value={address.website}
              validators={[
                new RequiredValidator(
                  "Website is required for online products"
                ),
                new WebsiteValidator(
                  'Website must inlclude "http://" or "https://'
                ),
              ]}
            />
          </div>
        </Products.Product>
      </Products> */}

      <div className="m-top-30">
        <h5>
          <span className="relative">
            <T>Company address</T>

            <Button
              onClick={() => setEditAddress((prev) => !prev)}
              action
              className={cx("mini", styles.addressButton, {
                [styles.hide]: editAddress,
              })}
            >
              <Edit />
            </Button>
          </span>
        </h5>

        <div className="text-small m-bottom-20">
          <AnimateHeight name={editAddress ? "on" : "off"}>
            {editAddress ? (
              <AddressWithSearch
                hideHeader
                address={
                  address.address || {
                    street: "",
                    postalCode: "",
                    city: "",
                    countryCode: "",
                  }
                }
                onChange={onAddressChange}
                addressRequiredFields={{
                  street: true,
                  city: true,
                  postalCode: true,
                  countryCode: true,
                }}
                showCountryCode
              />
            ) : (
              <div>
                {address.address.street}, {address.address.postalCode}{" "}
                {address.address.city}
                <br />
                <Flag
                  height={16}
                  rounded
                  country={address.address.countryCode as string}
                />{" "}
                {getCountryDisplayName(address.address.countryCode || "")}
              </div>
            )}
          </AnimateHeight>
        </div>
      </div>
    </Card>
  );
};
