import React, { FunctionComponent, RefObject, useRef } from "react";
import { useValidation, ValidationProps } from "./hooks/useValidation";
import { useForm } from "./hooks/useForm";
import cx from "classnames";
import "./HiddenInput.scss";
import { AnimateHeightMotion } from "../animate/AnimateHeightMotion";
import { createTranslation } from "../translation/T";

interface Props extends ValidationProps {
  value?: any;
  label: string;
  name?: string;
  scrollToRef?: RefObject<HTMLElement | null>;
  className?: string;
}

export const HiddenInput: FunctionComponent<Props> = ({
  value = "",
  name,
  validators = [],
  forceValidation = false,
  scrollToRef,
  className,
}) => {
  const innerRef = useRef<HTMLDivElement>(null);
  const inputId = useRef("text_" + Math.random().toString(36).substring(2, 9));

  const [validity, errorMessages, resetValidation, status] = useValidation(
    value,
    validators,
    forceValidation
  );
  useForm(
    inputId.current,
    validity,
    value,
    resetValidation,
    scrollToRef || innerRef
  );

  return (
    <div className={cx("hidden-input", status, className)} ref={innerRef}>
      <input
        value={value}
        type="hidden"
        id={inputId.current}
        name={name || inputId.current}
      />
      <AnimateHeightMotion presence>
        {errorMessages.length > 0 && (
          <div className="input-messages">
            <div className="input-message">
              {createTranslation(errorMessages[0])}
            </div>
          </div>
        )}
      </AnimateHeightMotion>
    </div>
  );
};
