import { useAtom, useSetAtom } from "jotai";
import { TranslationManagementOverlay } from "./TranslationManagementOverlay";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "../interactions/Buttons/Button";
import { AnimatePresence, motion } from "framer-motion";
import { TranslationList } from "./TranslationList";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { dataTranslation } from "./dataTranslation";
import {
  translationManagementState,
  ResetRegisteredTranslationsSelector,
  translationStateSelector,
  TranslationsSelector,
  translationStateActiveSelector,
} from "./translationState";
import "./TranslationManagement.scss";
import { Language } from "../../data/models/ContractTypes";
import { PersistentOverlay } from "../overlay/PersistantOverlay";

export interface TranslationAlternative {
  value: string;
  translated?: string;
}

export const TranslationManagement: React.FunctionComponent = () => {
  const [
    { translation, registeredTranslations, translations: translationTexts },
  ] = useAtom(translationManagementState);
  const [active, setActive] = useAtom(translationStateActiveSelector);

  const resetRegisteredTranslations = useSetAtom(
    ResetRegisteredTranslationsSelector
  );
  const setTranslation = useSetAtom(translationStateSelector);

  const [, setTranslationTexts] = useAtom(TranslationsSelector);

  const [showList, setShowList] = useState(true);
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    setShowList(active);
  }, [active]);

  const refreshTranslations = useCallback(
    () =>
      dataTranslation
        .loadTranslations(i18n.language as Language, true)
        .then(setTranslationTexts),
    [i18n.language, setTranslationTexts]
  );

  useEffect(() => {
    refreshTranslations();
  }, [refreshTranslations, i18n.language]);

  const translationText = useMemo(
    () => translationTexts?.find((t) => t.key === translation?.selected),
    [translation, translationTexts]
  );

  const alternatives = useMemo(
    () =>
      translation?.alternatives?.map(
        (value): TranslationAlternative => ({
          value,
          translated: translationTexts?.find((t) => t.key === value)?.value,
        })
      ),
    [translation, translationTexts]
  );

  const handleClose = async () => {
    refreshTranslations()
      .catch((error: Error) => {
        console.log(error);
      })
      .finally(() => setTranslation(undefined));
  };

  useEffect(() => {
    resetRegisteredTranslations();
  }, [location.pathname, resetRegisteredTranslations]);

  return (
    <div className="translation-management">
      <PersistentOverlay
        onClose={handleClose}
        open={!!translation}
        className="translation-management-overlay"
      >
        {translation && (
          <TranslationManagementOverlay
            translationText={translationText}
            onClose={handleClose}
            onRefresh={refreshTranslations}
            alternatives={alternatives}
            selected={translation.selected}
          />
        )}
      </PersistentOverlay>
      <div className="translation-menu">
        <AnimatePresence>
          {showList && (
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{
                opacity: { type: "tween", duration: 0.15 },
                y: { type: "spring", duration: 0.5 },
              }}
              key="list"
            >
              <TranslationList
                onClose={() => setShowList(false)}
                translationTexts={translationTexts}
              />
            </motion.div>
          )}
          {!showList && (
            <div className="list-buttons" key="buttons">
              {active ? (
                <>
                  <motion.div layoutId="translation-list-button">
                    <Button ghost onClick={() => setShowList(true)}>
                      View{" "}
                      {registeredTranslations.length > 0
                        ? registeredTranslations.length
                        : "all"}{" "}
                      translations on page
                    </Button>
                  </motion.div>
                  <motion.div layoutId="translation-exit-button">
                    <Button
                      className="danger-button"
                      block
                      onClick={() => setActive(false)}
                    >
                      Pause translation mode
                    </Button>
                  </motion.div>
                </>
              ) : (
                <motion.div layoutId="translation-exit-button">
                  <Button block onClick={() => setActive(true)}>
                    Enter translation mode
                  </Button>
                </motion.div>
              )}
            </div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};
