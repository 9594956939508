import { useParams } from "react-router";
import { LinkId } from "../data/models/ContractTypes";
import { useAtomValue } from "jotai";
import { routeState } from "../state/routeState";
import { Access } from "../data/proxy";

export type LinkIdParams = {
  linkId: LinkId;
};

export function useLinkId() {
  const { linkId } = useParams<LinkIdParams>();
  const routeConfig = useAtomValue(routeState);

  if (routeConfig.access === Access.VIEW) {
    return routeConfig.linkId as LinkId;
  }

  return linkId as LinkId;
}
