import React, { useState, useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BankType, ConfirmedStatus, dataBank } from "../../../../data/dataBank";
import { T } from "../../../../components/translation/T";
import { SavedAccount } from "../SavedAccount";
import { ListBanks } from "./ListBanks";
import { Dynamic } from "../../../../components/animate/Dynamic";
import { Status } from "../../../../data/types";
import { GenericError } from "../../../../components/Errors/GenericError";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Accounts } from "./Accounts";
import { useSearchParams } from "react-router-dom";
import { Form } from "../../../../components/form/Form";
import { HiddenInput } from "../../../../components/form/HiddenInput";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { StoryContinueButton } from "../../../../components/story/StoryContinueButton";
import { useAccess } from "../../../../hooks/useAccess";
import { Access } from "../../../../data/proxy";
import { useMutation } from "@tanstack/react-query";
import { useLinkId } from "../../../../hooks/useLinkId";
import { LegalEntityType } from "../../../../data/models/ContractTypes";
import { useAtomValue } from "jotai";
import { contractState } from "../../../../state/contractState";

interface Props {
  next: () => void;
  disabled?: boolean;
  externalAccount: ConfirmedStatus;
}

const QUERY_KEY = "status";
const QUERY_RESULT_SUCCESS = "success";
const QUERY_RESULT_ERROR = "error";

export const Roaring: React.FunctionComponent<Props> = ({
  next,
  disabled,
  externalAccount,
}) => {
  const ref = useRef<HTMLFormElement>(null);
  const linkId = useLinkId();
  const access = useAccess();
  const contract = useAtomValue(contractState);
  const { t } = useTranslation();
  const [iban, setIban] = useState<string>();
  const [params, setParams] = useSearchParams();
  const callbackStatus = params.get(QUERY_KEY);
  const [ownerType, setOwnerType] = useState<BankType | undefined>(
    contract.contractData.legalEntityType === LegalEntityType.SOLE_PROPRIETARY
      ? undefined
      : BankType.BUSINESS
  );
  const [status, setStatus] = useState<Status>(
    callbackStatus === QUERY_RESULT_SUCCESS
      ? Status.SUCCESS
      : callbackStatus === QUERY_RESULT_ERROR
      ? Status.ERROR
      : Status.DEFAULT
  );

  useEffect(() => {
    setParams();
  }, [setParams]);

  const {
    mutate: onSave,
    isPending: isLoading,
    isError,
    reset,
  } = useMutation({
    mutationFn: () => dataBank(access).selectRoaringAccount(linkId, iban || ""),
    onSuccess: () => {
      next();
    },
  });

  const validators = useMemo(() => {
    if (status === Status.SUCCESS) {
      return [new RequiredValidator("You must select a bank account")];
    }

    if (status === Status.ERROR) {
      return [new RequiredValidator("Retry bank login in order to proceed")];
    }

    if (!ownerType) {
      return [
        new RequiredValidator(
          "Please select the owner of your account and login to your bank in order to view applicable accounts"
        ),
      ];
    }

    return [
      new RequiredValidator(
        "Please login to your bank in order to add the bank account"
      ),
    ];
  }, [status, ownerType]);

  return (
    <div>
      <h2>
        <T>Payout account</T>
      </h2>
      <p>
        <T>
          We need you to add a payout account that will be used for your
          transactions.
        </T>
      </p>

      <SavedAccount
        disabled={disabled}
        next={next}
        externalAccount={externalAccount}
      />

      <Form
        onSubmit={(_, form) => {
          if (form.isInvalid) {
            return;
          }

          onSave();
        }}
      >
        <Dynamic name={status}>
          {Status.ERROR === status ? (
            <GenericError>
              {t("The bank login either failed or was aborted. Try again?")}

              <div className="m-top-20">
                <Button
                  ghost
                  block
                  onClick={() => {
                    setParams();
                    setStatus(Status.DEFAULT);
                  }}
                >
                  <T>Retry</T>
                </Button>
              </div>
            </GenericError>
          ) : null}

          {Status.SUCCESS === status ? (
            <Accounts setStatus={setStatus} iban={iban} setIban={setIban} />
          ) : null}

          {Status.DEFAULT === status ? (
            <ListBanks
              setOwnerType={setOwnerType}
              ownerType={ownerType}
              contract={contract}
            />
          ) : null}
        </Dynamic>

        <div className="p-top-20">
          <HiddenInput
            label="Iban"
            value={iban ? true : undefined}
            validators={validators}
            scrollToRef={ref}
          />
        </div>

        <Dynamic name={isError ? "isError" : ""}>
          {isError ? (
            <GenericError>
              {t("We couldn't list the applicable banks.")}
              <div className="m-top-20">
                <Button
                  ghost
                  block
                  onClick={() => {
                    if (!ownerType) {
                      return;
                    }
                    reset();
                    onSave();
                  }}
                >
                  {t("Try again?")}
                </Button>
              </div>
            </GenericError>
          ) : null}
        </Dynamic>

        <div className="m-top-20">
          <StoryContinueButton
            type="submit"
            disabled={access === Access.VIEW}
            isLoading={isLoading}
          >
            <T>Continue</T>
          </StoryContinueButton>
        </div>
      </Form>
    </div>
  );
};
