import cx from "classnames";
import {
  useMutation,
  useQueryClient,
  useSuspenseQueries,
} from "@tanstack/react-query";
import { useCallback, useMemo, useRef, useState } from "react";
import { AnimateHeight } from "../../../components/animate/AnimateHeight";
import { GenericError } from "../../../components/Errors/GenericError";
import { Form } from "../../../components/form/Form";
import { TextInput } from "../../../components/form/TextInput";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { WebsiteValidator } from "../../../components/form/validators/WebsiteValidator";
import { Checkboxes } from "../../../components/interactions/Checkboxes/Checkboxes";
import { StoryStepProps } from "../../../components/story/Story";
import { StoryContinueButton } from "../../../components/story/StoryContinueButton";
import { T } from "../../../components/translation/T";
import { useLinkId } from "../../../hooks/useLinkId";
import { Products, Product } from "../../../components/products/Products";
import { Country, ProductType } from "../../../data/models/ContractTypes";
import { useAtomValue } from "jotai";
import { contractState } from "../../../state/contractState";
import { dataStores } from "../../../data/dataStores";
import {
  EcomStore,
  WebStore,
  WebsiteChecklist,
} from "../../../data/dataMerchant";
import { WarningBox } from "../../../components/boxes/WarningBox";
import { Trans, useTranslation } from "react-i18next";
import { SelfDeclaration } from "../../../components/selfDeclaration/SelfDeclaration";
import { Dropdown } from "../../../components/interactions/Dropdown/Dropdown";
import { Status } from "../../../data/types";
import { CountryChip } from "../../../components/chip/CountryChip";
import { getCountryOpts } from "../../../components/utils";
import { HiddenInput } from "../../../components/form/HiddenInput";
import styles from "./WebsiteInformation.module.scss";
import { routeState } from "../../../state/routeState";
import { Access } from "../../../data/proxy";

const MAX_NUMBER_OF_POSSIBLE_COUNTRIES = 5;

const CHECKLIST_ALTERNATIVES = [
  {
    value: "hasPrivacyPolicy",
    text: "Privacy policy is present",
  },
  {
    value: "hasTermsOfService",
    text: "Terms of service is present",
  },
  {
    value: "hasCookiePolicy",
    text: "Cookie policy is present",
  },
  {
    value: "hasDataProtectionPolicy",
    text: "Data protection policy is present",
  },
  {
    value: "hasDataSecurityPolicy",
    text: "Data security policy is present",
  },
  {
    value: "hasDataRetentionPolicy",
    text: "Data retention policy is present",
  },
];

function isAllChecked(ecomStore: EcomStore) {
  const checklist = ecomStore.web?.checklist ?? {};
  return CHECKLIST_ALTERNATIVES.every(
    (alt) => checklist[alt.value as keyof WebsiteChecklist]
  );
}

function addPrefix(url?: string) {
  if (!url) {
    return "";
  }

  if (url.startsWith("https://")) {
    return url;
  }

  if (url.startsWith("http://")) {
    return url;
  }

  return `https://${url}`;
}

function stripPrefix(url?: string) {
  if (!url) {
    return;
  }

  if (url.startsWith("https://")) {
    return url.substring("https://".length, url.length);
  }

  if (url.startsWith("http://")) {
    return url.substring("http://".length, url.length);
  }

  return url;
}

export const WebsiteInformation: React.FunctionComponent<StoryStepProps> = ({
  next,
}) => {
  const ref = useRef<HTMLFormElement>(null);
  const linkId = useLinkId();
  const contract = useAtomValue(contractState);
  const { access } = useAtomValue(routeState);
  const { i18n, t } = useTranslation();

  const [{ data: store }] = useSuspenseQueries({
    queries: [dataStores(access).fetchEcomStore(linkId)],
  });

  const [hasShownWarningAtLeastOnce, setHasShownWarningAtLeastOnce] =
    useState<boolean>(false);
  const [showWarning, setShowWarning] = useState<boolean>(false);

  const [ecomStore, setEcomStore] = useState<WebStore>({
    web: {
      testUrl: "",
      checklist: {},
    },
    url: contract.contractData.website,
    ...store,
  });
  const queryClient = useQueryClient();

  const {
    mutate: onSave,
    isPending: isLoading,
    isError,
  } = useMutation({
    mutationFn: () => {
      // Make sure we send a test url even though it might be an empty one
      return dataStores(access).saveEcomStore(linkId, {
        ...ecomStore,
        web: {
          ...ecomStore.web,
          testUrl: ecomStore.web.testUrl || ecomStore.url,
        },
      });
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: dataStores(access).getEcomStoresKey(linkId),
      });
      next();
    },
  });

  const checklist = Object.keys(ecomStore.web?.checklist ?? {}).filter(
    (key) => (ecomStore.web?.checklist || {})[key as keyof WebsiteChecklist]
  );

  const setWeb = useCallback((value: string, name: string) => {
    if (name === "url") {
      setEcomStore((prev) => ({
        ...prev,
        url: addPrefix(value),
      }));
      return;
    }

    setEcomStore((prev) => ({
      ...prev,
      web: {
        ...prev.web,
        [name]: name === "testUrl" ? addPrefix(value) : value,
      },
    }));
  }, []);

  const numberOfCountries =
    (ecomStore.mainCountriesOfOperation || []).length ?? 0;

  const countries = useMemo(
    () =>
      getCountryOpts(
        t,
        i18n.language,
        false,
        undefined,
        undefined,
        ecomStore.mainCountriesOfOperation
      ),
    [ecomStore.mainCountriesOfOperation, t, i18n.language]
  );

  const onChange = useCallback((value: string) => {
    setEcomStore((prev) => ({
      ...prev,
      mainCountriesOfOperation: [
        ...(prev.mainCountriesOfOperation || []),
        value,
      ],
    }));
  }, []);

  const onRemove = useCallback((value: string) => {
    setEcomStore((prev) => ({
      ...prev,
      mainCountriesOfOperation: (prev.mainCountriesOfOperation || []).filter(
        (country) => country !== value
      ),
    }));
  }, []);

  return (
    <>
      <Form
        className="flex-1"
        ref={ref}
        onSubmit={(_, form) => {
          if (!form.isValid) {
            return;
          }

          if (hasShownWarningAtLeastOnce) {
            onSave();
            return;
          }

          if (!isAllChecked(ecomStore)) {
            setHasShownWarningAtLeastOnce(true);
            setShowWarning(true);
            return;
          }

          onSave();
        }}
      >
        <h2>
          <T>Website information</T>
        </h2>
        <div className="tablet-columns">
          <div className="m-top-20">
            <TextInput
              disabled={isLoading}
              name="url"
              label="URL"
              value={stripPrefix(ecomStore.url)}
              onChange={setWeb}
              validators={[
                new RequiredValidator("URL is required"),
                new WebsiteValidator("URL is not valid", true),
              ]}
              preIcon={{
                icon: "https://",
                width: 78,
              }}
            />
          </div>
          <div className="m-top-20">
            <TextInput
              disabled={isLoading}
              name="testUrl"
              label="Test URL"
              value={stripPrefix(ecomStore.web?.testUrl)}
              onChange={setWeb}
              validators={[new WebsiteValidator("URL is not valid", true)]}
              preIcon={{
                icon: "https://",
                width: 78,
              }}
            />
          </div>
        </div>

        <Products productType={contract.productType}>
          <Product
            products={[ProductType.ACCEPTANCE_ONLINE, ProductType.CHECKOUT]}
          >
            <div className="m-top-30">
              <Dropdown
                required={
                  ecomStore.mainCountriesOfOperation?.length ? false : true
                }
                onChange={onChange}
                name="mainCountriesOfOperation"
                label="Main countries in which the company operates (20% or more of company turnover)"
                className={cx("compact", {
                  "was-successful":
                    numberOfCountries > 0 &&
                    numberOfCountries !== MAX_NUMBER_OF_POSSIBLE_COUNTRIES,
                })}
                alternatives={countries}
                value=""
                status={
                  numberOfCountries === MAX_NUMBER_OF_POSSIBLE_COUNTRIES
                    ? Status.DISABLED
                    : Status.DEFAULT
                }
              />

              <div className={styles.chips}>
                {(ecomStore.mainCountriesOfOperation || []).map(
                  (citizenship) => {
                    return (
                      <CountryChip
                        key={citizenship}
                        country={citizenship as Country}
                        onRemove={onRemove}
                        data={citizenship}
                      />
                    );
                  }
                )}
              </div>

              <HiddenInput
                label="mainCountriesOfOperation_validation"
                value={numberOfCountries > 0 ? true : undefined}
                validators={[
                  new RequiredValidator("You must select at least one country"),
                ]}
                scrollToRef={ref}
              />
            </div>
          </Product>
        </Products>

        <div className="m-top-40">
          <Checkboxes
            label="Website checklist"
            name="checklist"
            disabled={isLoading}
            values={checklist}
            onChange={(values) => {
              const checklist = CHECKLIST_ALTERNATIVES.reduce(
                (acc, { value }) => ({
                  ...acc,
                  [value]: values.includes(value),
                }),
                {} as WebsiteChecklist
              );

              const store = {
                ...ecomStore,
                web: {
                  ...ecomStore.web,
                  checklist,
                },
              };

              if (isAllChecked(store)) {
                setShowWarning(false);
              } else {
                if (hasShownWarningAtLeastOnce) {
                  setShowWarning(true);
                }
              }

              setEcomStore(store);
            }}
            alternatives={CHECKLIST_ALTERNATIVES}
          />
        </div>

        <Products productType={contract.productType}>
          <Product products={[ProductType.ACCEPTANCE_ONLINE]}>
            <hr />
            <SelfDeclaration
              setWebStore={setEcomStore}
              ecomStore={ecomStore}
              formRef={ref}
            />
          </Product>
        </Products>

        <div className="m-top-30">
          <AnimateHeight name={showWarning ? "warning" : ""}>
            <div>
              {showWarning ? (
                <WarningBox relative>
                  <Trans t={t}>
                    <strong>Heads up</strong>{" "}
                    <span className="text-large">👋</span>!<br />
                    Before we can activate payment transactions, all of the{" "}
                    <b>Website checklist</b> options <u>must be</u> fulfilled.
                    However, at this moment you are still able to continue with
                    the application.
                  </Trans>
                </WarningBox>
              ) : (
                <div />
              )}
            </div>
          </AnimateHeight>

          <AnimateHeight name={isError ? "error" : ""}>
            <div>{isError ? <GenericError /> : <div />}</div>
          </AnimateHeight>
        </div>

        <div className="m-top-30">
          <StoryContinueButton
            disabled={access === Access.VIEW}
            type="submit"
            isLoading={isLoading}
          >
            <T>Continue</T>
          </StoryContinueButton>
        </div>
      </Form>
    </>
  );
};
