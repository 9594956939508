import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import cx from "classnames";
import styles from "./ViewerIsSigneeNotSigned.module.scss";
import {
  Associate,
  Contract,
  SigningMethod,
} from "../../../../data/dataMerchant";
import { T } from "../../../../components/translation/T";
import { Field } from "../Field";
import { getNameWithPosition } from "../Signatures";
import { Country, UTCDate } from "../../../../data/models/ContractTypes";
import { Image } from "../../../../components/images/Image";
import { Status } from "../../../../data/types";
import { Spinner } from "../../../../components/spinner/Spinner";
import { ErrorBox } from "../../../../components/boxes/ErrorBox";
import { SigningStatus, dataSigning } from "../../../../data/dataSigning";
import { useLinkId } from "../../../../hooks/useLinkId";
import { Link } from "../../../../components/link/Link";
import { TermsAndConditions } from "../TermsAndConditions";
import { WarningBox } from "../../../../components/boxes/WarningBox";
import { useAccess } from "../../../../hooks/useAccess";
import { Access } from "../../../../data/proxy";
import { SIGNATORY_URL } from "../../../SignatoryCollection/SignatoryCollection";
import { generatePath, matchPath, useSearchParams } from "react-router-dom";
import { DATA_COLLECTION_STORY_ROUTES } from "../../dataCollectionRoutes";
import { DATA_COLLECTION_STORY_URL } from "../../DataCollectionStory";
import { Trans, useTranslation } from "react-i18next";
import { Select } from "../../../../components/form/Select";
import { InfoBox } from "../../../../components/boxes/InfoBox";
import { Dynamic } from "../../../../components/animate/Dynamic";
import { Pending } from "../../../../components/icons/Pending";
import { Validations } from "../../Signatories/components/forms/AssociateIdFields";
import {
  isValidFinnishSSN,
  isValidNorwegianSSN,
} from "../../../../components/validationUtils";

interface Props {
  viewer: Associate;
  country: Country;
  includeName?: boolean;
  disabled?: boolean;
  associates: Associate[];
  setAssociates: React.Dispatch<React.SetStateAction<Associate[]>>;
  contract: Contract;
  contractIncludesDcc: boolean;
}

type ImageType = {
  alt: string;
  src: string;
  ratio: number;
  className: string;
};

const SIGNICAT_LINK = "https://www.signicat.com/";

const ProviderNames: Record<SigningMethod, string> = {
  [SigningMethod.BANKID]: "🇸🇪 BankID",
  [SigningMethod.NBANKID]: "🇳🇴 BankID",
  [SigningMethod.MITID]: "🇩🇰 MitID",
  [SigningMethod.FTN]: "🇫🇮 Finnish Trust network",
};

const logos: Record<SigningMethod, ImageType> = {
  [SigningMethod.BANKID]: {
    className: styles[SigningMethod.BANKID],
    alt: "Bank id logo",
    src: "/logos/BankID_619.2x513.svg",
    ratio: 619.2 / 513,
  },
  [SigningMethod.NBANKID]: {
    className: styles[SigningMethod.NBANKID],
    alt: "Bank id logo",
    src: "/logos/Norwegian_bankid_140x21.svg",
    ratio: 140 / 21,
  },
  [SigningMethod.MITID]: {
    className: styles[SigningMethod.MITID],
    alt: "Bank id logo",
    src: "/logos/MitID_1280x333.63934.svg",
    ratio: 1280 / 333.63934,
  },
  [SigningMethod.FTN]: {
    className: styles[SigningMethod.FTN],
    alt: "Bank id logo",
    src: "/logos/FTN_152x40.svg",
    ratio: 152 / 40,
  },
};

const QUERY_EDITABLE = "edit";
export const QUERY_KEY = "status";
export const QUERY_SUCCESS = "success";
export const QUERY_ERROR = "error";
export const QUERY_ABORT = "abort";
export const UUID = "uuid";

const POLLING_TIMER = 3000;
const MAX_NUMBER_OF_ATTEMPTS = 30;

type Response = Status | "ABORTED" | "COMPLETED";

// const AVERAGE_ORG = "559264-1947";
const QUERY_EDITABLE_VALUE = "true";
export const QUERY_POLL_KEY = "poll";
export const QUERY_POLL_VALUE = "true";
export const QUERY_ORIGIN_KEY = "origin";
export const QUERY_ORIGIN_VALUE = "summary";
export const QUERY_PROVIDER_KEY = "provider";

function getDefaultProvider(country: Country) {
  if (country === Country.DENMARK) {
    return SigningMethod.MITID;
  }

  if (country === Country.FINLAND) {
    return SigningMethod.FTN;
  }

  if (country === Country.NORWAY) {
    return SigningMethod.NBANKID;
  }

  return SigningMethod.BANKID;
}

export function getSigningMethodBasedOnSsnOrCountry(
  ssn: string,
  country: Country
): SigningMethod {
  if (ssn.length === Validations[Country.SWEDEN].hint.length) {
    return SigningMethod.BANKID;
  }

  if (ssn.length === Validations[Country.DENMARK].hint.length) {
    return SigningMethod.MITID;
  }

  if (isValidFinnishSSN(ssn)) {
    return SigningMethod.FTN;
  }

  if (isValidNorwegianSSN(ssn)) {
    return SigningMethod.NBANKID;
  }

  return getDefaultProvider(country);
}

export const ViewerIsSigneeNotSigned: React.FunctionComponent<Props> = ({
  viewer,
  country,
  includeName = true,
  disabled = false,
  setAssociates,
  contract,
  contractIncludesDcc,
}) => {
  const [status, setStatus] = useState<Response>(Status.DEFAULT);
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const [termsWarning, setTermsWarning] = useState<boolean>(false);
  const linkId = useLinkId();
  const access = useAccess();
  const timer = useRef<number>(undefined);
  const attempts = useRef<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const editable = useRef<boolean>(
    searchParams.get(QUERY_EDITABLE) === QUERY_EDITABLE_VALUE
  );

  const [provider, setProvider] = useState<SigningMethod>(
    getSigningMethodBasedOnSsnOrCountry(
      viewer.id.nationalPersonId || "",
      country
    )
  );

  const signRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => {
      window.clearTimeout(timer.current);
    };
  }, []);

  const poll = useCallback(() => {
    dataSigning(access)
      .poll(linkId)
      .then((data) => {
        if (data.status === SigningStatus.COMPLETED) {
          setAssociates((prev) =>
            prev.map((associate) => {
              if (associate.associateId === viewer.associateId) {
                return {
                  ...associate,
                  signatory: {
                    signed: new Date().toUTCString() as UTCDate,
                  },
                };
              }

              return associate;
            })
          );
          setStatus("COMPLETED");
          return;
        }

        if (
          data.status === SigningStatus.ERROR ||
          data.status === SigningStatus.REJECTED
        ) {
          setStatus(Status.ERROR);
          return;
        }

        if (data.status === SigningStatus.ABORTED) {
          setStatus("ABORTED");
          return;
        }

        if (attempts.current > MAX_NUMBER_OF_ATTEMPTS) {
          setStatus(Status.ERROR);
          return;
        }

        attempts.current = attempts.current + 1;
        timer.current = window.setTimeout(poll, POLLING_TIMER);
      })
      .catch((err) => {
        setStatus(Status.ERROR);
      });
  }, [linkId, setAssociates, access, viewer]);

  useEffect(() => {
    const shouldPoll = searchParams.get(QUERY_POLL_KEY) === QUERY_POLL_VALUE;
    const provider = searchParams.get(QUERY_PROVIDER_KEY);

    if (shouldPoll) {
      setStatus(Status.PENDING);
      setProvider(provider as SigningMethod);
      setProvider(
        (searchParams.get(QUERY_PROVIDER_KEY) as SigningMethod) ||
          getDefaultProvider(country)
      );
      setSearchParams("");
      setAcceptedTerms(true);
      timer.current = window.setTimeout(() => {
        poll();
      }, 2000);

      if (signRef.current) {
        signRef.current.scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
      }
    }
  }, [searchParams, poll, country, setSearchParams]);

  const onClick = useCallback(() => {
    if (!acceptedTerms) {
      setTermsWarning(true);
      return;
    }

    setStatus(Status.PENDING);

    const match = matchPath(
      {
        path: `${DATA_COLLECTION_STORY_URL}${DATA_COLLECTION_STORY_ROUTES.SUMMARY.path}`,
      },
      document.location.pathname
    );

    const redirectUrl = `${document.location.origin}${generatePath(
      SIGNATORY_URL,
      {
        linkId,
      }
    )}`;

    const params = new URLSearchParams();
    params.append(QUERY_POLL_KEY, QUERY_POLL_VALUE);
    params.append(QUERY_PROVIDER_KEY, provider);
    if (match) {
      params.append(QUERY_ORIGIN_KEY, QUERY_ORIGIN_VALUE);
    }
    const returnUrl = `${redirectUrl}?${params.toString()}`;
    dataSigning(access)
      .sign(linkId, {
        onComplete: returnUrl,
        onReject: returnUrl,
        onPostpone: returnUrl,
        method: provider,
      })
      .then((resp) => {
        window.location.href = resp.signUrl;
      })
      .catch(() => {
        setTimeout(() => {
          setStatus(Status.ERROR);
        }, 1000);
      });
  }, [linkId, acceptedTerms, access, provider]);

  const elem = useMemo(() => {
    if (status === Status.ERROR) {
      return (
        <div className={styles.signatureError}>
          <ErrorBox relative>
            <T>Ooh, no. Something went wrong. Try again?</T>
          </ErrorBox>
        </div>
      );
    }

    if (status === "ABORTED") {
      return (
        <div className={styles.signatureError}>
          <ErrorBox relative>
            <T>Seems like the signing was aborted. Try again?</T>
          </ErrorBox>
        </div>
      );
    }

    if (status === Status.PENDING) {
      return (
        <div className={styles.signatureError}>
          <InfoBox relative>
            <T>Waiting for signature </T> &nbsp;
            <Pending />
          </InfoBox>
        </div>
      );
    }

    return null;
  }, [status]);

  return (
    <>
      {editable.current ? (
        <EditableProvider
          provider={provider}
          setProvider={setProvider}
          status={status}
        />
      ) : (
        <FixedProvider provider={provider} />
      )}

      <div className={styles.highlightBox}>
        <div className="m-bottom-20">
          <TermsAndConditions
            contractIncludesDcc={contractIncludesDcc}
            disabled={status === Status.PENDING}
            setAcceptedTerms={(accepted) => {
              setAcceptedTerms(accepted);
              setTermsWarning(false);
            }}
            contract={contract}
            acceptedTerms={acceptedTerms}
          />
        </div>
      </div>

      <div className={styles.signatureHeader} ref={signRef}>
        <T>Signatures</T>
      </div>

      <div className={styles.termsWarning}>
        <Dynamic name={termsWarning ? "warning" : "no-warning"}>
          {termsWarning ? (
            <div className={styles.warningBox}>
              <WarningBox relative>
                <div className={styles.warnidngInner}>
                  <T>You must agree to the terms before signing.</T>
                </div>
              </WarningBox>
            </div>
          ) : null}
        </Dynamic>
      </div>

      <Dynamic name={status}>{elem}</Dynamic>

      <div className={styles.provider}>
        <div className={styles.providerDropdown}>
          <div className={styles.nameAndField}>
            {includeName && (
              <div className={styles.signeeName}>
                {getNameWithPosition(viewer)}
              </div>
            )}

            <Field name={<T>Name</T>}>
              <button
                onClick={onClick}
                className={cx(styles.idField, styles[status])}
                disabled={disabled || access === Access.VIEW}
              >
                <div className={cx(styles.buttonOverlay, styles[status])}>
                  <Spinner size="small" color="white" />
                </div>
                <T>Sign application</T>
              </button>
            </Field>
          </div>
        </div>
        <div className={cx(styles.providerIcon, logos[provider].className)}>
          <Image {...logos[provider]} className={styles.image} />
        </div>
      </div>
    </>
  );
};

const EditableProvider: React.FunctionComponent<{
  provider: SigningMethod;
  setProvider: (value: React.SetStateAction<SigningMethod>) => void;
  status: Response;
}> = ({ provider, status, setProvider }) => {
  const { t } = useTranslation();

  const opts = useMemo(() => {
    return Object.values(SigningMethod).map((item) => ({
      value: item,
      text: ProviderNames[item],
    }));
  }, []);

  return (
    <div className={styles.highlightBox}>
      <h5>
        <T>Signature provider</T>
      </h5>
      <div className={styles.signicatIngress}>
        <div>
          <Trans t={t}>
            We will use{" "}
            <Link external link={SIGNICAT_LINK} className={styles.signicatLink}>
              <div className={styles.signicatLogo}>
                <Image
                  ratio={1054.5 / 572.6}
                  alt="Signicat logo"
                  src="/logos/Signicat_1054.5x572.6.svg"
                />
              </div>
              Signicat
            </Link>{" "}
            as a provider for digital signatures. You are able to select your
            preferred signing method in the dropdown list below.
          </Trans>
        </div>
      </div>

      <div className={styles.provider}>
        <div className={styles.providerDropdown}>
          <Select
            onChange={(value) => setProvider(value)}
            name="provider"
            label="Select signing method"
            alternatives={opts}
            value={provider}
            disabled={status === Status.PENDING}
          />
        </div>

        <div
          className={cx(
            styles.providerIcon,
            logos[provider].className,
            styles.altBg
          )}
        >
          <Image {...logos[provider]} className={styles.image} />
        </div>
      </div>
    </div>
  );
};

const FixedProvider: React.FunctionComponent<{
  provider: SigningMethod;
}> = ({ provider }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.highlightBox}>
      <h5>
        <T>Signature provider</T>
      </h5>

      <div className={styles.fixed}>
        <div>
          <Trans t={t}>
            We will use{" "}
            <Link external link={SIGNICAT_LINK} className={styles.signicatLink}>
              <div className={styles.signicatLogo}>
                <Image
                  ratio={1054.5 / 572.6}
                  alt="Signicat logo"
                  src="/logos/Signicat_1054.5x572.6.svg"
                />
              </div>
              Signicat
            </Link>{" "}
            as a provider for digital signatures.
          </Trans>
        </div>

        <div
          className={cx(
            styles.providerIcon,
            logos[provider].className,
            styles.altBg
          )}
        >
          <Image {...logos[provider]} className={styles.image} />
        </div>
      </div>
    </div>
  );
};
