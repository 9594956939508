import { RefObject } from "react";

export function scrollToError(scrollToRef?: RefObject<HTMLElement | null>) {
  if (!document) {
    return;
  }

  const scrollToElement = scrollToRef?.current;

  if (!document || !scrollToElement) {
    return;
  }

  scrollToElement.scrollIntoView({
    block: "center",
    inline: "center",
    behavior: "smooth",
  });
}
