import React, { useMemo } from "react";
import styles from "./SignatoryCollection.module.scss";
import { dataAssociates } from "../../data/dataAssociates";
import { useLinkId } from "../../hooks/useLinkId";
import { contractState } from "../../state/contractState";
import { Logo } from "../../components/images/Logo";
import { T } from "../../components/translation/T";
import { getViewer } from "../DataCollection/ContractLoader";
import { SummaryInner } from "../DataCollection/Summary/Summary";
import { Trans, useTranslation } from "react-i18next";
import { TI } from "../../i18n";
import { dataFinancial } from "../../data/dataFinancial";
import { LanguageSwitch } from "../../components/languageSwitch/LanguageSwitch";
import { Access } from "../../data/proxy";
import { useAtomValue } from "jotai";
import { useSuspenseQueries } from "@tanstack/react-query";

export const SIGNATORY_URL = "/:linkId/signatory";

export const SignatoryCollection: React.FunctionComponent = () => {
  const linkId = useLinkId();
  const { t } = useTranslation();
  const contract = useAtomValue(contractState);
  const [{ data: financial }, { data: associates }] = useSuspenseQueries({
    queries: [
      dataFinancial(Access.VIEW_AND_EDIT).fetchFinancial(linkId),
      dataAssociates(Access.VIEW_AND_EDIT).fetchAssociates(linkId),
    ],
  });

  const viewer = useMemo(
    () => getViewer(contract.contractViewer, associates),
    [associates, contract]
  );

  const { companyName } = contract.contractData;
  const name = `${viewer?.contact.firstName} ${viewer?.contact.lastName}`;

  return (
    <section>
      <article>
        <div className={styles.wrapper}>
          <div className="logo-wrapper">
            <Logo />
          </div>
          <div className="m-top-30">
            <LanguageSwitch />
          </div>
          <div className={styles.header}>
            <h2>
              <T
                id="Hi, {{name}}!"
                options={{
                  name: `${viewer?.contact.firstName} ${viewer?.contact.lastName}`,
                }}
              />
            </h2>
          </div>
          <p>
            <Trans t={t}>
              <strong>{{ companyName } as TI}</strong> has been registered for
              Worldline payment services by <strong>{{ name } as TI}</strong>.
              Since you are a person authorized to sign for the firm we need a
              signature from you in order to proceed with the application.
            </Trans>
          </p>

          <SummaryInner data={contract.contractData} financial={financial}>
            <div />
          </SummaryInner>
        </div>
      </article>
    </section>
  );
};
