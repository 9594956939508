import { API } from "../network/API";
import { ContractDocument, DocumentType, LinkId } from "./models/ContractTypes";
import { Access, getProxy } from "./proxy";

const BASE_PATH = "/api/merchant";

export function getDescription(doc: ContractDocument, t: any) {
  if (doc.documentType === DocumentType.SALES_CUSTOM_REQUEST) {
    return doc.documentDescription;
  }

  return t(doc.documentType);
}

enum WhitelistedEndpoint {
  FETCH_DOCUMENTS = "fetchDocuments",
  GET_DOCUMENTS = "getDocuments",
  GET_DOCUMENTS_KEY = "getDocumentsKey",
}

export const Documents = {
  [WhitelistedEndpoint.FETCH_DOCUMENTS]: (linkId: LinkId) => ({
    queryKey: Documents.getDocumentsKey(linkId),
    queryFn: () =>
      API.get<ContractDocument[]>(`${BASE_PATH}/${linkId}/documents`),
  }),

  deleteDocument: (endpoint: string) => API.delete(endpoint),

  [WhitelistedEndpoint.GET_DOCUMENTS]: (linkId: LinkId) =>
    API.get<ContractDocument[]>(`${BASE_PATH}/${linkId}/documents`),

  [WhitelistedEndpoint.GET_DOCUMENTS_KEY](linkId: LinkId) {
    return ["documents", linkId];
  },
};

let docs: typeof Documents;

export function dataDocuments(access: Access) {
  if (!docs) {
    docs = getProxy(Documents, access, Object.values(WhitelistedEndpoint));
  }

  return docs;
}
